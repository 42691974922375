import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import auth from './auth';
import user from './user';
import feed from './feed';

export default combineReducers({
    auth,
    user,
    feed,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
});
