import React, { Component } from "react";
import firebase from 'firebase/compat/app';
import Countdown, { zeroPad } from 'react-countdown';
import debounce from 'debounce-promise';

// import ReactCardFlip from 'react-card-flip';

import { connect } from "react-redux";
import { Button, Spinner } from 'reactstrap';
import PollResults from "./PollResults";
import { withToastManager } from 'react-toast-notifications';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import AsyncSelect from 'react-select/async';

import { FiChevronDown } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { IoSend } from "react-icons/io5";

import { answerPoll, getPoll, shufflePoll, addFriend, inviteUser } from "../actions/user";
import Reward from "react-rewards";

// import CustomPoll from "./CustomPoll";
import FriendFinder from "./FriendFinder";
import Tips from "./Tips";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { IoPeople } from 'react-icons/io5';

class Poll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceStart: false,
      invitee: '',
      startPolls: false,
      timerKey: 0,
      flipped: false,
      revealed: false,
      chosen: -1,
      focused: 0,
      disabled: false,
      modal: false,
      pollsReady: false,
      disableButton: false,
      searching: false,
      searchResults: {},
      mode: localStorage.getItem('mode') === 'flirtatious' ? 'flirtatious' : 'friendly',
      choices: [],
      friend: [] 
    };
  }

  forceStart = () => {
    if (this.state.forceStart) {
      this.startPolls();
      this.setState({ forceStart: false });
    }
  }

  scalePercentages(friends) {
    // create a list of friends -> total_votes
    // sort the list by total_votes

    // do not sort the input
    let sorted_students = friends.slice(0);
    sorted_students.sort((a, b) => {
      return (b.chosen + b.not_chosen) - (a.chosen + a.not_chosen);
    });

    // for each friend in sorted_friends, scale their percentage by the remaining percentage
    
    let remaining_percentage = 1.0;

    for (let i = 0; i < sorted_students.length; i++) {
      let student = sorted_students[i];

      let chosen_percentage = (student.chosen + student.not_chosen > 0 ? student.chosen : 0.0) / (student.chosen + student.not_chosen + 1);
      let scaled_percentage = chosen_percentage * remaining_percentage;
      
      // set the scaled percentage to a random number between 0 and 100
      student.scaled_percentage = Math.random() * 100;

      remaining_percentage -= scaled_percentage;
      if (remaining_percentage < 0.0) {
        remaining_percentage = 0.0;
      }
    }

    // Neo4j database needs updated to not include duplicates and only one edge per node
    

    // now normalize the percentages so that they add up to 1.0
    let total = 0.0;
    for (let i = 0; i < sorted_students.length; i++) {
      total += sorted_students[i].scaled_percentage;
    }

    for (let i = 0; i < sorted_students.length; i++) {
      sorted_students[i].scaled_percentage /= total;
    }
  }

  setChoice(friend, index) {
    const { toastManager } = this.props;

    // choose a friend
    // update the poll in the database
    // update the poll in the redux store
    let choices = this.props.user.poll.choices ? this.props.user.poll.choices : [];
    choices[index].chosen += 1;
    
    // for each friend not in the index
    for (let i = 0; i < choices.length; i++) {
      if (i !== index) {
        choices[i].not_chosen += 1;
      }
    }

    this.scalePercentages(choices); 

    // send some request here to update the poll in the database
    firebase.auth().currentUser.getIdToken().then((token) => {
      this.props.answerPoll(token, index, !this.state.revealed, toastManager, this.poll_reward);
    });

    this.setState({ revealed: true, chosen: index, disabled: true, choices: choices });   
  }

  refreshSuggestions = () => {
    return new Promise((resolve) => {
        this.props.refreshSuggestions();
        setTimeout(() => {
            resolve();
        }
        , 2000);
    });
  }

  shuffle = () => {
    if (!this.state.revealed) {
      firebase.auth().currentUser.getIdToken().then((token) => {
        this.props.shufflePoll(this.props.user.poll.question[0], token);
      });
    }
  }

  startPolls = () => {
    firebase.auth().currentUser.getIdToken().then((token) => {
      this.props.getPoll(false, false, this.state.mode, token);
      this.setState({ startPolls: true, forceStart: false, });
    });
  }

  addNewFriend(friend) {
    const { toastManager } = this.props;
    firebase.auth().currentUser.getIdToken().then((token) => {
      this.props.addFriend(token, friend, toastManager);
    });
  }

  fetchOptions = (query) => {
    return fetch(`https://api-lookup.dartmouth.edu/v1/lookup?q=${encodeURIComponent(query)}&includeAlum=false&field=dcDeptclass&field=uid&field=displayName&field=eduPersonPrimaryAffiliation`).then(res => {
        return res.json();
    }).then(json => {
        return json.users.map(user => {
            return {
              value: user.uid,
              label: user.displayName,
              affiliation: user.eduPersonPrimaryAffiliation,
              class: user.dcDeptclass,
          }
        })
    });
  }

  renderVisibility = (totalFriends) => {
    if (totalFriends <= 2) {
        return "low 😞"
    } else if (totalFriends <= 5) {
        return "moderate 🙆‍♀️"
    } else if (totalFriends <= 10) {
        return "strong 🔥"
    } else {
        return "superstar 🤩"
    }
  }

  render() {
    /* const stringSplitter = string => {
      const splitter = new GraphemeSplitter();
      return splitter.splitGraphemes(string);
    }; */
    let choices = this.props.user.poll ? this.props.user.poll.choices : [];
    const poll_question_text = this.props.user.poll ? this.props.user.poll.question[1] : '';
    const poll_question = this.props.user.poll ? poll_question_text : '';

    const width = document.getElementsByClassName("elem")[0] ? document.getElementsByClassName("elem")[0].offsetWidth : 400;
    const poll_width = document.getElementsByClassName("poll")[0] ? document.getElementsByClassName("poll")[0].offsetWidth : 0;

    const students = choices.map((choice, index) => {
      return (
        <section key={index} className="kappa">
          <button 
            className={"c-btn c-btn-tetrary " + (this.state.chosen !== -1 && this.state.chosen === index ? "chosen-elem" : "") }
            onClick={() => !this.state.disabled ? this.setChoice(choice, index) : null }
          >{ choice.display_name.toLowerCase() }
            <div className={`poll-loading ` + (this.state.chosen !== -1 && this.state.chosen === index ? "chosen" : "") + (!this.state.revealed ? " hidden" : "")} style={{ width: this.state.revealed ? this.state.choices[index].scaled_percentage * width : 0 }}></div>
          </button>
        </section>
      );
    });

    // let potential_friends_list be a list of suggestions who are not already friends
    const potential_friends_list = this.props.user.suggestions ? this.props.user.suggestions.filter(
      (potentialFriend) => {
        return !this.props.user.friends.find(friend => potentialFriend.value === friend.value);
      }
    ) : [];

    const potential_friends = potential_friends_list.map(
      (friend, index) => {
        return (
          <li key={index} style={{ opacity: this.props.user.toBeAdded[friend.value] ? '0.5' : '1.0' }}>
            <div className="potential-friend-container">
              <span className="potential-label">{ friend.label.toLowerCase() }, { friend.class }</span>
              <div className="potential-friend-actions">
                <Button onClick={() => this.addNewFriend(friend)}>
                  <span className="plus">
                    +
                  </span>
                </Button>
              </div>
            </div>
          </li>
        )
      }
    );

    const fetchOpts = debounce(this.fetchOptions, 273);

    const customStyles = {
      control: (provided, state) => ({
          ...provided,
          border: 'none',
          width: '100%',
          fontSize: '1.25em',
          borderRadius: '15px',
          boxShadow: '1px 1px 5px #0000001a',
          padding: '3px',
          paddingLeft: '15px',
          background: '#ffffff52',
          '&:hover': {
              boxShadow: '0 0 0 0.2rem #0000001f',
              cursor: 'pointer'
          },
      })
    } 
    // const hideTip = false; // localStorage.getItem('tip') === this.props.user.tip ? true : false;
    //           <span id="tip">{ this.props.user.tip }</span>

    // filler names for the suggestions will be based on suggestions from the user
    // choose 4 random names for the suggestions: this.props.user.suggestions (array of objects)
    // if there are less than 4 suggestions, then just use the suggestions
    // if there are no suggestions, then use the filler names

    let filler_names = ['john m.', 'daniel d.', 'luke h.', 'penelope t.']

    if (this.props.user.suggestions.length >= 4) {
      // get a random 4 names from the suggestions
      filler_names = this.props.user.suggestions.map((suggestion, index) => {
        return suggestion.label;
      }).sort(() => Math.random() - 0.5).slice(0, 4);
    }

    const addFriendStyle = {
      fontSize: '1.1em', 
      padding: '0px 18px', 
      marginLeft: '15px',
      borderRadius: '15px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '46px',
      width: '50px',
  }

    // let timestamp be the current time plus the number of minutes stored in this.props.user.time_to_wait
    const timestamp = Date.now() + (this.props.user.time_to_wait) * 60000;

    const timeoutBox = this.props.user.timeout ? (
      <div className="poll-overlay unveal" style={{ textAlign: 'center', justifyContent: 'center' }}>
          <h2>🏆 🥰 🔥</h2>
          <p style={{ fontSize: '1.5em' }}>for making someones day, you've received <b>{ this.props.user.reveals_reward }</b> reveal{ this.props.user.reveals_reward > 1 ? 's' : '' }!</p>
          <p style={{ opacity: '0.3' }}>come back in {<Countdown 
          renderer={({ hours, minutes, seconds }) => (
            <span>
              {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
          )}
          date={timestamp} />} for more questions and to receive more compliments!</p>
          <div className="skip-wait">
            <h5>...or skip the wait by inviting a friend!</h5>
                <div className='friend-actions'>
                  <AsyncSelect
                    getOptionLabel={e => {
                      return <h6>{ e.label }, { e.class }</h6>
                    }}
                    getOptionValue={e => e.value}
                    placeholder="search for a friend"
                    loadOptions={fetchOpts}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}

                    onChange={e => this.setState({ invitee: e.value })}
                    isSearchable
                    className="custom-option-container"
                    classNamePrefix="custom-option"
                    styles={customStyles}
                  />
                  <Button 
                        style={{...addFriendStyle }}
                        disabled={this.state.invitee === ''}
                        onClick={(e) => {
                            const { toastManager } = this.props;
                            e.preventDefault();
                            firebase.auth().currentUser.getIdToken().then((token) => {
                              this.props.inviteUser(this.state.invitee, token, toastManager)
                            });
                        }}
                    >
                        <span className="send-invite">
                            { !this.props.user.inviteSending ? <IoSend /> : <Spinner size="sm" /> }
                        </span>
                    </Button>
                </div>
          </div>
          <p><b style={{ fontSize: '0.9em' }}>use reveals below to find out who complimented you!</b></p>
          <div className="down-icon">
            <FiChevronDown />
          </div>
        </div> 
    ) : '';



    // NEED: skip the wait feature for user to invite their friends!

    return (
      <>
        { ((!this.state.startPolls && this.props.user.poll === null) || this.state.forceStart || this.props.user.friends.length < 5) && <Tips totalFriends={this.props.user.friends.length} /> }
        { (this.state.startPolls || this.props.user.poll !== null) && (!this.state.forceStart) && (this.props.user.friends.length >= 5) &&
        <>
          <div className="big-wrapper">
            <Reward ref={(ref) => { this.poll_reward = ref }} type='confetti'>
              { this.props.user.suggestions && <div className="friends-wrapper">
                <div className="potential-friends">
                  <div className="potential-friends-list">
                    <ul>
                      { potential_friends }
                    </ul>
                  </div>
                </div>
              </div> }
              <div className={"poll " + (this.props.user.pollLoading ? "transition" : "" ) + (this.props.user.timeout ? ' timeout-height' : '')}>
                { timeoutBox }
                <div className={"poll-progress " + (this.props.user.timeout ? "fast-blur transition" : "")} style={{ width: (poll_width - 10.0) * (this.props.user.question_limit !== 0 && this.props.user.session_polls_answered >= 1 ? (this.props.user.session_polls_answered - 1) / this.props.user.question_limit : 0) }}></div>
                <h2 className={this.props.user.timeout ? "fast-blur transition" : ""}> { poll_question }</h2>
                <div className={"poll-options " + (this.props.user.timeout ? "fast-blur transition" : "")}>
                    <ul>
                        { students }
                    </ul>
                </div>
                <div className={"poll-footer " + (this.props.user.timeout ? "fast-blur" : "")}>
                  <span>
                    { !this.state.revealed && <div className={"shuffle " + (this.props.user.shuffling ? 'spinning' : '') } onClick={() => this.shuffle() } style={{ opacity: this.state.revealed ? '0.5' : '1.0', display: 'flex', alignItems: 'center' }}>
                      <HiOutlineRefresh />
                      <p style={{ margin: 0, marginLeft: 10, opacity: 0.3, fontWeight: 'bold' }}>shuffle students</p>
                    </div> }
                    { this.state.revealed && <span className="count-timer"><CountdownCircleTimer
                      isPlaying={this.state.revealed}
                      duration={5}
                      size={28}
                      initialRemainingTime={5}
                      key={this.state.timerKey}
                      strokeWidth={5}
                      colors={['#232039']}
                      trailColor="#0000001f"
                      onComplete={() => {
                        if (this.props.question_limit === this.props.session_polls_answered + 1) {
                          this.setState({ disableButton: true });
                        }

                        firebase.auth().currentUser.getIdToken().then((token) => {
                          this.props.getPoll(true, false, this.state.mode, token);
                          this.setState({ revealed: false, chosen: -1, disabled: false, timerKey: this.state.timerKey + 1 });
                        });

                        return { shouldRepeat: true };
                      }}
                    >
                    </CountdownCircleTimer></span> }</span>
                  <div className="poll-footer-action">
                    <Button onClick={() => {
                      if (this.props.question_limit === this.props.session_polls_answered + 1) {
                        this.setState({ disableButton: true  });
                      }

                      firebase.auth().currentUser.getIdToken().then((token) => {
                        this.props.getPoll(true, !this.state.revealed, this.state.mode, token);
                        this.setState({ revealed: false, chosen: -1, disabled: false, timerKey: this.state.timerKey + 1 });
                      });
                    }} disabled={this.props.user.pollLoading || this.state.disableButton || this.props.user.timeout}>{ (this.props.question_limit < this.props.session_polls_answered + 1 || this.props.user.timeout) ? 'finish' : (this.state.revealed ? 'next' : 'skip') }</Button> 
                  </div>
                  
                </div>
              </div>
            </Reward>
            { /* <ReactCardFlip isFlipped={this.state.flipped} flipDirection="vertical">
              <CustomPoll />
            </ReactCardFlip> */ }
          </div>
          { !this.props.user.timeout && <div className="poll-supplement">
            <div className="poll-config" onClick={() => { 
                                this.setState({ mode: this.state.mode === 'friendly' ? 'flirtatious' : 'friendly'}, () => {
                                    localStorage.setItem('mode', this.state.mode);
                                });
                            }}>
              { this.state.mode !== 'friendly' ? <ImCheckboxUnchecked /> : <ImCheckboxChecked /> }
              <p><b>{ this.state.mode === 'friendly' ? 'wholesome mode on' : 'wholesome mode off' }</b></p>
            </div>
          </div> }
        </> }
        { ((!this.state.startPolls && this.props.user.poll === null) || this.state.forceStart || this.props.user.friends.length < 5) &&
        <>
          <div className="poll friend-finder" id="target">
            <div className="poll-overlay unveal">
              <FriendFinder 
                refreshSuggestions={() => this.refreshSuggestions()}
              />
            </div>
            <h2 className="delayed-blur">charming and always knows how to make a good impression? 😉</h2>
            <div className="poll-options delayed-blur">
                <ul>
                  { filler_names.map((name, index) => {
                    return (<li className="elem" key={index}>
                      <span className="poll-label locked">{ name.toLowerCase() }</span>
                    </li>)
                   }) }
                </ul>
            </div>
            <div className="poll-footer delayed-blur">
              <span></span>
              <Button>skip</Button>
            </div>
          </div>
        </> 
        }
        <br /><br />
        <div className="poll-results">
          <div className="poll-results-header">
            <h4>what students said about you...</h4>
            <br />
            { /* <div className="devious">
              <Button
              color="primary"
              outline
              className="devious-button"
              onClick={() => this.setState({ flipped: !this.state.flipped })}
              >
                <span id="devious">
                  { (!this.state.flipped) ? <span className="devious-tag">feelin' devious? <FaArrowRight /> </span> : <span className="return-tag"><FaArrowLeft /> go back</span> }
                </span>
              </Button>
            </div>
           */ }
          </div>
          <br />
          <div className="results-underlay">
              <div className="results-underlay-actions">
                  <p className="visibility-info"><span className="vis-text">your visibility is</span> <span className="vis-span" data-tooltip-id="my-tooltip" data-tooltip-content="A greater visibility means you'll be seen in more polls!" data-tooltip-place="bottom" >{ this.renderVisibility(this.props.user.friends.length) }</span></p>
                  { (this.props.user.friends.length < 5) ? <b>add { 5 - this.props.user.friends.length } more friend{ (5 - this.props.user.friends.length) === 1 ? '' : 's' } to begin!</b> : ((!this.state.startPolls && this.props.user.poll === null) || this.state.forceStart ? <Button className="start-button pulse" disabled={this.props.user.friends < 5 || this.props.user.pollLoading} onClick={() => this.startPolls()}>start &rarr;</Button> : <div className="add-more-friends"><Button disabled={this.props.user.pollLoading || this.state.revealed} onClick={() => this.setState({ startPolls: false, forceStart: true })}>add friends <IoPeople /></Button></div>) }
              </div>
          </div>
          <PollResults />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
  getPoll: (new_poll, skipped, mode, token) => dispatch(getPoll(new_poll, skipped, mode, token)),
  addFriend: (token, friend, toastManager) => dispatch(addFriend(token, friend, toastManager)),
  answerPoll: (token, answer, skipped, toastManager, rewardManager) => dispatch(answerPoll(token, answer, skipped, toastManager, rewardManager)),
  shufflePoll: (qid, token) => dispatch(shufflePoll(qid, token)),
  inviteUser: (uid, token, toastManager) => dispatch(inviteUser(uid, token, toastManager)),
});

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(Poll));