import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container } from 'reactstrap';
import ChooseUser from './ChooseUser';
import firebase from 'firebase/compat/app';
import { withToastManager } from 'react-toast-notifications';

import Spinner from 'react-bootstrap/Spinner';

import { sendLoginEmail, fetchPreview, registerUser } from '../actions/user';

class SignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            friends: [],
            user: null,
            addedUser: false,
            addedFriends: false,
            currentStep: 1,
            accountConfirmed: false,
            newUser: false,
            message: `use your dartmouth undergrad account`,
        }
    }

    /* componentDidMount() {
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            // the email should be contained in the GET parameter email in the url
            // use URLSearchParams to get the email
            let searchParams = new URLSearchParams(window.location.search);
            let email = searchParams.get('email');

            firebase.auth().signInWithEmailLink(email, window.location.href)
              .then((result) => {

                // get the token from the current user
                firebase.auth().currentUser.getIdToken().then((token) => {

                    // absolutely need to add a flag here to indicate that the user is new
                    if (result.additionalUserInfo.isNewUser) {
                        this.props.registerUser(token);
                    }

                    this.setState({ currentStep: 2, accountConfirmed: true });
                    window.localStorage.setItem('authenticated', true);
                    //this.setState({ accountConfirmed: true, newUser: result.additionalUserInfo.isNewUser });
                });                
              })
              .catch((error) => {
                console.log(error);
                this.setState({ message: "oops! looks like that link didn't work, try sending again?" });
              });
            }
    } */

    setUser = (user) => {
        this.setState({ user });
    }

    nextStep = () => {
        this.setState({ currentStep: this.state.currentStep + 1 });
    }

    prevStep = () => {
        this.setState({ currentStep: this.state.currentStep - 1 });
    }
    
    finish = () => {
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                window.location.href = '/home';
            });
        }
    }

    render() {
        const { toastManager } = this.props;

        if (this.props.user.registering) {
            return (
                <div className="d-flex justify-content-center align-items-center" style={{height: '90vh', flexDirection: 'column', opacity: 0.3}}>
                  <div className="sr-only" style={{ marginBottom: 15 }}>don't look, I'm getting ready! 🙈</div>
                  <Spinner animation="border" role="status" />
                </div>
            )
        }

        return (
            <Container className="signup-container">
                { <ChooseUser 
                    registerUser={this.props.registerUser} 
                    user={this.state.user} 
                    finish={this.finish} 
                    authEmailSending={this.props.user.authEmailRequest} 
                    message={this.state.message} 
                    setUser={this.setUser} 
                    toastManager={toastManager} 
                    sendLoginEmail={this.props.sendLoginEmail} /> }
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = dispatch => ({
    registerUser: (token) => dispatch(registerUser(token)),
    sendLoginEmail: (user, toastManager) => dispatch(sendLoginEmail(user, toastManager)),
    fetchPreview: (affirmationID) => dispatch(fetchPreview(affirmationID)),
});


export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(SignUp));