import React, { useRef, useEffect, useState } from 'react';

const BezierCurve = () => {
  const containerRef = useRef();
  const canvasRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      const { offsetWidth: width, offsetHeight: height } = containerRef.current;
      setDimensions({ width, height });
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const { width, height } = dimensions;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const devicePixelRatio = window.devicePixelRatio || 1;

    canvas.width = width * devicePixelRatio;
    canvas.height = height * devicePixelRatio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    ctx.scale(devicePixelRatio, devicePixelRatio);

    // Clear the canvas
    ctx.clearRect(0, 0, width, height);

    // Draw the Bezier curve
    ctx.beginPath();
    ctx.moveTo(3, 0); // Start at the top left corner
    ctx.bezierCurveTo(3, height / 2, width / 2, height / 2, width + 10, height / 2); // Adjust control points
    ctx.lineWidth = 3;
    ctx.strokeStyle = '#232039';

    // make the curve dotted (not dashed)
    // ctx.setLineDash([5, 5]);

    // draw an emoji in the middle of the curve
    // ctx.font = '20px serif';
    // ctx.fillText(emoji, width / 2, (height / 2) - 15);

    ctx.stroke();
  }, [dimensions]);

  return (
    <div ref={containerRef} style={{ height: '100%', width: '100%' }}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default BezierCurve;