import { createGlobalStyle } from "styled-components"
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text};
    transition: color linear;
  }

  .navbar-text {
    color: ${({ theme }) => theme.navbar_text} !important;
    transition: color 0.50s linear;
  }

  .poll-progress {
    background: ${({ theme }) => theme.poll_progress} !important;
    opacity: 0.162 !important;
  }
  

  .locked {
    color: transparent !important;
    text-shadow: 0 0 12px white !important;
    margin-right: 5px !important;
    opacity: 0.5;
    transition: all 0.50s linear;
  }

  .name {
    color: ${({ theme }) => theme.text};
    text-shadow: 0 0 0px ${({ theme }) => theme.text};
    margin-right: 5px !important;
  }

  .score-board p {
    color: ${({ theme }) => theme.text} !important;
  }

  .Toastify__toast-container div {
    background: ${({ theme }) => theme.menu_option} !important;
    font-weight: bold;
    font-family: 'MyFont';
    color: ${({ theme }) => theme.fill};
  }

  .nav-link {
    color: ${({ theme }) => theme.fill} !important;
    transition: color 0.50s linear;
  }

  .active {
    color: white !important;
  }

  .add-friends-select__control {
    background: ${({ theme }) => theme.poll} !important;
    color: ${({ theme }) => theme.text} !important;
  }

  .add-friends-select__multi-value {
    background: ${({ theme }) => theme.friend_option} !important;
 }

  .add-friends-select__input-container {
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.50s linear;
  }

  .add-friends-select__multi-value__label {
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.50s linear;
  }

  .poll-label {
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.50s linear;
  }

  .elem {
    border-color: ${({ theme }) => theme.poll_choice_border} !important;
  }

  .chosen-elem {
    border-color: ${({ theme }) => theme.poll_choice_chosen_border} !important;
  }

  .poll-options li {
    background: ${({ theme }) => theme.poll_choice} !important;
    transition: all 0.2s linear;
  }
  
  .container {
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.50s linear;
  }

  .slider:before {
    background: ${({ theme }) => theme.switch_knob} !important;
  }

  .unveal {
    z-index: 1;
    background: ${({ theme }) => theme.overlay_background} !important;
  }

  .poll-percent {
    color: ${({ theme }) => theme.fill} !important;
    transition: all 0.50s linear;
  }

  .chosen {
    background-color: ${({ theme }) => theme.chosen} !important;
    transition: all 0.50s linear;
  }

  .poll-footer span, .footer-layout-group span  {
    color: ${({ theme }) => theme.text} !important;
  }

  .devious-button {
    /* #3737375e */
    color: ${({ theme }) => theme.fill} !important;
    border: 3px solid ${({ theme }) => theme.devious_button} !important;
    background: #ffffff1a !important;
  }

  .devious-button:hover {
    color: white !important;
  }

  .add-friends-select__placeholder {
    color: ${({ theme }) => theme.placeholder} !important;
    transition: all 0.50s linear;
 }

 .custom-question__menu, .custom-option__menu, .custom-recipient__menu, .add-friends-select__menu {
    background: ${({ theme }) => theme.menu_background} !important;
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.50s linear;
 }

 .custom-option__input-container, .custom-recipient__input-container {
    color: ${({ theme }) => theme.text} !important;
 }

 .add-friends-select__option:hover, 
 .add-friends-select__option--is-focused,
 .custom-option__option:hover,
 .custom-option__option--is-focused,
 .custom-question__option--is-focused,
  .custom-question__option:hover,
 .custom-recipient__option:hover,
 .custom-recipient__option--is-focused {
    background: ${({ theme }) => theme.menu_option_hover} !important;
    color: ${({ theme }) => theme.text} !important;
 }

 input+.slider {
  background-color: ${({ theme }) => theme.switch_background} !important;
 }
 .affirmations-header h3 {
  color: ${({ theme }) => theme.text} !important;
 }

 .people {
  opacity: ${({ theme }) => theme.people_opacity} !important;
 }

 .tip {
  background-color: ${({ theme }) => theme.tip} !important;
  color: ${({ theme }) => theme.text} !important;
 }

 .custom-question__single-value, .custom-option__single-value, .custom-recipient__single-value {
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.50s linear;
 }

 .custom-option__control {
    background: ${({ theme }) => theme.poll_choice} !important;
    transition: all 0.50s linear;
 }

 .custom-recipient__control {
    background: ${({ theme }) => theme.poll_choice} !important;
    transition: all 0.50s linear;
 }

  .poll {
    background-color: ${({ theme }) => theme.poll} !important;
    color: ${({ theme }) => theme.text} !important;
    box-shadow: 0 0 10px ${({ theme }) => theme.poll_shadow} !important;
    transition: all 0.50s linear;
  }

  .poll-results h4 {
    color: ${({ theme }) => theme.text} !important;
  }

  .result-chosen {
    border: 3px solid ${({ theme }) => theme.result_choice_border} !important;
  }

  .result-header {
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.50s linear;
  }

  .result-header h1 {
    opacity: ${({ theme }) => theme.poll_result_header_opacity} !important;
    transition: all 0.50s linear;
  }

  .potential-friends-list h5 {
    opacity: 0.15 !important;
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.50s linear;
  }

  .sr-only {
    color: ${({ theme }) => theme.text} !important;
  }

  .result-header h5 {
    opacity: 0.6 !important;
  }

  .result {
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.50s linear;
  }

  .Collapsible>span>.card {
    background-color: ${({ theme }) => theme.card} !important;
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.50s linear;    
  }

  .friend {
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.50s linear;
  }

  .card {
    background-color: ${({ theme }) => theme.card} !important;
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.50s linear;
  }

  svg {
    fill: ${({ theme }) => theme.fill} !important;
    transition: all 0.50s linear;
  }

  .shuffle svg {
    color: ${({ theme }) => theme.fill} !important;
  }

  .count-timer>div>svg path:nth-child(1) {
    stroke: ${({ theme }) => theme.timer_track} !important;
  }

  .count-timer>div>svg path:nth-child(2) {
    stroke: ${({ theme }) => theme.timer_path} !important;
  }

  .potential-friends li {
    background-color: ${({ theme }) => theme.potential_friend_background} !important;
    border: 3px solid ${({ theme }) => theme.potential_friend_border} !important;
  }

  .potential-overlay {
    background: linear-gradient(-90deg, ${({ theme }) => theme.body}, ${({ theme }) => theme.body}, transparent) !important;
    transition: all 0.50s linear;
  }

  .potential-label {
    color: ${({ theme }) => theme.potential_friend_color} !important;
  }

  .plus {
    color: ${({ theme }) => theme.potential_friend_color} !important;
  }

  .bin svg {
    fill: ${({ theme }) => theme.potential_friend_color} !important;
  }

`