import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Form, FormGroup, Label, Button, Navbar, NavbarBrand, NavbarText } from 'reactstrap';
import { FiChevronLeft } from 'react-icons/fi';

import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { registerUser } from "../actions/user";

class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possibleIdentityUid: '',
            message: 'use your dartmouth undergrad account',
        }
    }

    fetchOptions = (query) => {
        return fetch(`https://api-lookup.dartmouth.edu/v1/lookup?q=${query}&field=displayName&field=mail&field=dcDeptclass&field=uid`).then(res => {
            return res.json();
        }).then(json => {
            return json.users.map(user => {
                return {
                    value: user.uid,
                    label: user.displayName,
                    email: user.mail,
                    year: user.dcDeptclass,
                }
            })
        });
    }

    verify = () => {
        if (this.state.possibleIdentityUid !== '') {
            firebase.auth().currentUser.getIdToken().then((token) => {
                // if the user does not have a file in dartmouth/users table
                // we need to create one in users' table
                // do we even need the dartmouth one?

                // need to also pass in the id of the user selected
                this.props.registerUser(token, this.state.possibleIdentityUid);
                //this.setState({ accountConfirmed: true, newUser: result.additionalUserInfo.isNewUser });
            });
        }
    }


    render() {
        const fetchOpts = debounce(this.fetchOptions, 250);

        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                border: '1px solid #ced4da',
                width: '100%',
                fontSize: '1.5rem',
                borderRadius: '0.25rem',
                '&:hover': {
                    borderColor: '#80bdff',
                    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
                    cursor: 'pointer'
                },
            })
        }
        
        // if the verify button does not work
        // i.e. the users google account != directory email
        // then we fall back to email verification

        return (
            <Container className="signup-container">
                <Form style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
                    <FiChevronLeft className="back-arrow" />
                    <FormGroup>
                        <Label for="exampleEmail">
                            let's get started
                        </Label>
                        <AsyncSelect
                        styles={customStyles}
                        getOptionLabel={e => e.label}
                        getOptionValue={e => e.value}
                        placeholder="what is your name?"
                        loadOptions={fetchOpts}
                        onChange={(e) => this.setState({ possibleIdentityUid: e.value })}
                        isSearchable
                        />
                    </FormGroup>
                    <Navbar expand="md">
                        <NavbarBrand>
                            <h6 style={{ opacity: 0.5 }}>{ (this.props.user.register_error === null) ? this.state.message : this.props.user.register_error }</h6>
                        </NavbarBrand>
                        <NavbarText style={{ textAlign: 'right' }}>
                            <Button 
                            style={{ width: 100, textAlign: 'center', marginLeft: 'auto' }}
                            onClick={() => this.verify()}
                            disabled={this.props.user.registering || this.state.possibleIdentityUid === ''}>{ this.props.user.registering ? 'verifying' : 'verify' }</Button>
                        </NavbarText>
                    </Navbar>
                </Form>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        registerUser: (token, possibleIdentityUid) => dispatch(registerUser(token, possibleIdentityUid)),
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);