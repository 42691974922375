import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiArrowRight } from 'react-icons/fi';
import { MovingComponent } from 'react-moving-text';
import { Container, Row, Col } from 'reactstrap';
// import Background from '../assets/bg.png';

class Landing extends Component {

    /* componentDidMount() {
        // set the background image
        document.body.style.backgroundImage = `url(${Background})`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.backgroundPosition = 'center';
        document.body.style.backgroundAttachment = 'fixed';

        // blur the background image not the contents

    }*/

    render() {
        return (
            <Container style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div className="landing">
                    <MovingComponent
                    type="fadeIn"
                    duration="1000ms"
                    delay="0.25s"
                    direction="normal"
                    timing="ease"
                    fillMode="backwards"
                    iteration="1">
                        <h1>loving pine</h1>
                    </MovingComponent>
                    <Row style={{ justifyContent: 'space-around' }}>
                        <Col style={{ textAlign: 'center', width: 300 }} lg="12" xs="12">
                            <MovingComponent
                            type="fadeIn"
                            duration="1000ms"
                            delay="0.50s"
                            direction="normal"
                            timing="ease"
                            fillMode="backwards"
                            iteration="1">
                                <p style={{ marginBottom: 0 }}>words of kindness from your peers, </p>
                            </MovingComponent>
                            <MovingComponent
                            type="fadeIn"
                            duration="1000ms"
                            delay="0.75s"
                            direction="normal"
                            timing="ease"
                            fillMode="backwards"
                            iteration="1">
                                <p style={{ marginBottom: 10 }}>to help you get through the day</p>
                            </MovingComponent>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MovingComponent
                            type="fadeIn"
                            duration="1000ms"
                            delay="1s"
                            direction="normal"
                            timing="ease"
                            fillMode="backwards"
                            iteration="1">
                                <a href={ this.props.auth.uid ? "/home" : "/signup" }>read yours here <FiArrowRight /></a>
                            </MovingComponent>
                        </Col>
                    </Row>
                    <Row style={{ position: 'fixed', bottom: 30, width: '90%', textAlign: 'center' }}>
                        <Col xs="12">
                            <MovingComponent
                            type="fadeIn"
                            duration="1000ms"
                            delay="1.25s"
                            direction="normal"
                            timing="ease"
                            fillMode="backwards"
                            iteration="1">
                            <a href="/tos">terms of service</a>
                            </MovingComponent>
                        </Col>
                        <Col xs="12">
                            <MovingComponent
                            type="fadeIn"
                            duration="1000ms"
                            delay="1.5s"
                            direction="normal"
                            timing="ease"
                            fillMode="backwards"
                            iteration="1">
                            <span id='counter'>3,968 affirmations and counting</span>
                            </MovingComponent>
                        </Col>
                        <Col xs="12">
                            <MovingComponent
                            type="fadeIn"
                            duration="1000ms"
                            delay="1.75s"
                            direction="normal"
                            timing="ease"
                            fillMode="backwards"
                            iteration="1">
                            <span id='credit'>built by students at dartmouth (not affilated with college)</span>
                            </MovingComponent>
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
});

export default connect(mapStateToProps)(Landing);