import React, { Component } from 'react';
import { FormGroup, FormFeedback, Input, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import debounce from 'debounce-promise';
import { withToastManager } from 'react-toast-notifications';

import { sendAffirmation } from '../actions/user';
import { connect } from 'react-redux';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import firebase from 'firebase/compat/app';

class AffirmModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            typing: false,
            typingTimeout: 0,
            query: '',
            recipientName: '',
            recipientEmail: '',
            to: '',
            qid: 0,
            message: '',
            submitted: false,
            questions: [
                { label: 'The moment we became friends...', value: 0 },
                { label: 'Our best freshmen year memory...', value: 1 },
                { label: 'Hardest class we faced at Dartmouth...', value: 2 },
                { label: 'The thing I\'m most proud of...', value: 3 },
                { label: 'The reason I admire you most...', value: 4 },
                { label: 'The one thing on our bucket list...', value: 5 },
                { label: 'The thing you will be the most famous for...', value: 6 },
                { label: 'The thing I love about you the most...', value: 7 },
                { label: "The all-nighter we'll never forget...", value: 8 },
                { label: "The time we survived a mid-term crisis...", value: 9 },
                { label: "The most memorable road trip or weekend getaway...", value: 10 },
                { label: "The best spring break adventure we shared...", value: 11 },
                { label: "The moment we knew we could rely on each other...", value: 12 },
                { label: "Our go-to stress-relief activity on campus...", value: 13 },
                { label: "The thing that always makes us laugh when we're together...", value: 14 },
              ]
        };
    }

    fetchOptions = (query) => {
        return fetch(`https://api-lookup.dartmouth.edu/v1/lookup?q=${query}&includeAlum=false`).then(res => {
            return res.json();
        }).then(json => {
            return json.users.map(user => {
                return {
                    to: user.uid,
                    recipientName: user.displayName,
                    recipientEmail: user.mail
                }
            })
        });
    }

    submit = () => {
        const { toastManager } = this.props;
        this.setState({ submitted: true });
        
        if (this.state.message.length > 0 && (this.state.to.length > 0 || this.props.modalUser.to !== '')) {
            if (firebase.auth().currentUser) {
                firebase.auth().currentUser.getIdToken().then((token) => {
                
                    this.props.sendAffirmation(
                        this.state.recipientName !== '' ? this.state.recipientName : this.props.modalUser.recipientName,
                        this.state.recipientEmail !== '' ? this.state.recipientEmail : this.props.modalUser.recipientEmail, 
                        this.state.to !== '' ? this.state.to : this.props.modalUser.to,
                        this.state.message, 
                        this.state.qid, 
                        token, 
                        toastManager);
                    
                        // reset state
                        this.setState({
                            values: [],
                            typing: false,
                            typingTimeout: 0,
                            query: '',
                            recipientName: '',
                            recipientEmail: '',
                            to: '',
                            qid: 0,
                            message: '',
                            submitted: false,
                        });

                        this.props.toggleModal();
                });
            }
        }

        // console.log(this.props.modalUser, 'modal user')

        // store the browser in the request etc for login, if the code does not match the current browser, display a message
        // but automatically send another

        // 20 questions per hour, algorithm
        // 1. store when the user is requesting a poll and when they last chose one
        // 2. we also store the total amount of polls per session, so once they reach 20, we record the time it took to reach 20
        // then once they come back after 60 minutes, they fetch again, we check server timestamps, if it's been 60 minutes since the recorded timestamp
        // then we reset the total polls to 0 and the timestamp to the current time.
        // fundamentally, store the timestamp at which they last requested a poll, then if the delta between current timestamp
        // and hasRequestedTimestamp is > 60, set total polls to 0, and set hasRequestedTimestamp to current timestamp
        // else, increment total polls by 1, once they get to 20 (only possible if deltas are < 60), then cool out.

        // 1. store the timestamp at which they last requested a poll
        // 2. store the total amount of polls they have requested in the current session
        // 3. if the delta between current timestamp and hasRequestedTimestamp is > 60, set total polls to 0, and set hasRequestedTimestamp to current timestamp
        // 4. else, increment total polls by 1, once they get to 20 (only possible if deltas are < 60), then cool out.
        // if total == 20, then cool out for 60 minutes, so reject requests until time difference between server and client is > 60 minutes
    
        // extra: all friends vs direct could be a new feature next week

        /* 

        <Input
                    id="exampleText"
                    name="text"
                    placeholder='write your affirmation here'
                    type="textarea"
                    onChange={(e) => this.setState({ message: e.target.value })}
                    resize="none"
                    invalid={this.state.submitted && this.state.message.length === 0}
                    />
                    <FormFeedback>
                        please enter an affirmation
                    </FormFeedback>

                    */
    }

    render() {
        const fetchOpts = debounce(this.fetchOptions, 1000);
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} centered>
                <ModalHeader toggle={this.props.toggleModal}>send an affirmation to { this.props.modalUser.recipientName !== '' ? this.props.modalUser.recipientName.toLowerCase() : 'someone' }</ModalHeader>
                <ModalBody>
                <AsyncSelect
                getOptionLabel={e => e.recipientName}
                getOptionValue={e => e.to}
                placeholder="search for a dartmouth friend"
                loadOptions={fetchOpts}
                defaultValue={this.props.modalUser.to !== '' ? this.props.modalUser : null}
                onChange={e => this.setState({ to: e.to, recipientName: e.recipientName, recipientEmail: e.recipientEmail })}
                isSearchable
                />
                <br />
                <Select
                placeholder="their questions"
                defaultValue={this.state.questions[0]}
                onChange={e => this.setState({ qid: e.value })}
                options={this.state.questions}/>
                <br />
                 <FormGroup>
                    <CKEditor
                        editor={ ClassicEditor }
                        config={{
                            placeholder: "write your affirmation here",
                            toolbar: ['heading', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'fontSize', 'undo', 'redo'],

                        }}
                        onReady={ editor => {
                        }}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            this.setState({ message: data })
                        }}
                        onBlur={ ( event, editor ) => {
                        }}
                        onFocus={ ( event, editor ) => {
                        }}
                    />
                </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={(e) => {
                        this.submit();
                        // this.props.toggleModal(e);
                    }} color="secondary" disabled={this.state.message.length === 0 || this.props.isSending}>
                        send &gt;
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        isSending: state.user.isSending,
        isSent: state.user.isSent,
        uid: state.user.uid,
        username: state.user.username,
        year: state.user.year,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendAffirmation: (recipientName, recipientEmail, to, message, qid, token, toastManager) => dispatch(sendAffirmation(recipientName, recipientEmail, to, message, qid, token, toastManager))
    }
}


export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(AffirmModal));