import React, { Component } from "react";
import { connect } from "react-redux";
import 'react-confirm-alert/src/react-confirm-alert.css';

import { Form, FormGroup, Label, Navbar, NavbarBrand, NavbarText } from 'reactstrap';
import { FiChevronLeft } from 'react-icons/fi';

import { GoogleButton } from 'react-google-button';

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

class ChooseUser extends Component {
    fetchOptions = (query) => {
        return fetch(`https://api-lookup.dartmouth.edu/v1/lookup?q=${query}&field=displayName&field=mail&field=dcDeptclass&field=uid`).then(res => {
            return res.json();
        }).then(json => {
            return json.users.map(user => {
                return {
                    value: user.uid,
                    label: user.displayName,
                    email: user.mail,
                    year: user.dcDeptclass,
                }
            })
        });
    }

    handleLogin = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        provider.addScope('profile');
        provider.addScope('email');
        provider.setCustomParameters({
            'hd': 'dartmouth.edu'
        });

        firebase.auth().signInWithPopup(provider).then((result) => {
            window.localStorage.setItem('authenticated', true);
            this.props.finish();
        }).catch((error) => {
            console.log(error);
        });
    }


    render() {
        return (
            <Form style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
                <FiChevronLeft className="back-arrow" />
                <FormGroup>
                    <Label for="exampleEmail">
                        sign in / register
                    </Label>
                    <GoogleButton style={{ background: '#ee45409c' }} onClick={() => this.handleLogin()} />
                </FormGroup>
                <Navbar expand="md">
                    <NavbarBrand>
                        <h6 style={{ opacity: 0.5 }}>{ this.props.message }</h6>
                    </NavbarBrand>
                    <NavbarText style={{ textAlign: 'right' }}>
                    </NavbarText>
                </Navbar>
            </Form>
        );
    }
}

export default connect()(ChooseUser);