import React, { Component } from 'react';
import { connect } from 'react-redux';

import { logoutUser, getFeed, viewAffirmations } from '../actions/user';
import { withToastManager } from 'react-toast-notifications';

import { Container, Button } from 'reactstrap';
import './main.css';
import firebase from 'firebase/compat/app';

import Affirmations from '../components/Affirmations';
import Header from '../components/Header';
import Poll from '../components/Poll';
import AffirmModal from '../components/AffirmModal';
import Feed from '../components/Feed';
import Friends from '../components/Friends';

import {ThemeProvider} from "styled-components";
import { GlobalStyles } from "../components/globalStyles";
import { darkTheme } from "../Theme";
import Register from '../signup/Register';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '3',
            modal: false,
            theme: localStorage.getItem('theme') === 'light' ? 'light' : 'dark',
            modalUser: { label: '', recipientName: '', recipientEmail: '', to: '' },
            componentLoaded: false,
            isTabActive: !document.hidden,
        };

        this.steps = [
            {
                target: '.poll',
                disableBeacon: true,
                content:
                    <div className="snack-info">
                        <h4>want compliments? 💕</h4>
                        <b>polls</b> are a great way to celebrate your friends and get compliments!
                    </div>
            },
            {
                target: '.friends-wrapper',
                content:
                    <div className="snack-info">
                        <h4>who are my friends? 👨‍👩‍👧</h4>
                        <b>friends</b> are the people you send affirmations to, and they'll also appear in your polls, you can add any Dartmouth student here!
                    </div>
           },
            {
                target: '.navbar-brand',
                content:
                    <div>
                        <h3>kindness awaits...</h3>
                        <p>it's nice to receive a message of affirmation from a peer, and it's even nicer to surprise someone else! (tap this icon to return to light mode ☀️)</p>
                    </div>
            },
            {
                target: '.tokens',
                content:
                    <div className="snack-info">
                        <h4>who sent me a compliment? 🤷‍♂️</h4>
                        <b>reveal tokens</b> show the person who sent you an affirmation, and you earn them by sending affirmations and answering polls about your friends.
                    </div>
            },
            {
                target: '.affirm-button',
                content:
                    <div className="snack-info">
                        <h4>send an affirmation 💌</h4>
                        a message of affirmation is a great way to brighten someone's day, especially if they are feeling down.<br /><br />
                    </div>
            }
        ];
        
        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    }

    fetchData() {
        const { toastManager } = this.props;

        if (this.state.isTabActive && this.props.user.uid) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getFeed(token, this.props.user.uid, this.props.feed.lastUpdated, toastManager);
            });
        }
    }

    componentDidMount() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        this.fetchData();
        this.interval = setInterval(() => this.fetchData(), 5000);
    }
    
    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        clearInterval(this.interval);
    }

    handleVisibilityChange() {
        this.setState({ isTabActive: !document.hidden });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    // flip it on its head
    // - instead of showing who voted for who
    // - celebrate those who have been chosen for each person

    toggleModal(e, user={ recipientName: '', recipientEmail: '', to: '' }) {
        this.setState({
            modal: !this.state.modal,
            modalUser: user,
        });
    }

    render() {
        // check if local storage has a theme set
        if (this.props.user.informationLoading) { // this.props.user.informationLoading
            return (
                <ThemeProvider theme={darkTheme}>
                    <GlobalStyles/>
                    <div className="d-flex justify-content-center align-items-center" style={{height: '90vh', flexDirection: 'column', opacity: 0.3}}>
                        <div className="loader"></div><br />
                        <div className="sr-only" style={{ marginBottom: 15, fontWeight: 'bold' }}>tip: don't click your name... 🐛</div>
                    </div>
                </ThemeProvider>
            )
        }

        // this should never be null as this.props.user.infoLoading will set it either way
        if (this.props.user.user_registered !== null) {
            if (this.props.user.user_registered === false) {
                return <Register />;
            }
        }

        return (
            <ThemeProvider theme={darkTheme}>
                <GlobalStyles/>
                <div>
                    <Container className="starting-block" style={{ maxWidth: 920 }} fluid="sm">
                        <Header toggleDarkMode={() => {
                            this.setState({ theme: 'dark' }, () => {
                                localStorage.setItem('theme', this.state.theme);
                            });
                        }} given={this.props.given} received={this.props.received} tokens={this.props.tokens} activeTab={this.state.activeTab} toggle={(tab) => this.toggle(tab)} />
                        { this.state.activeTab === '5' ? <Feed toggleModal={this.toggleModal} /> : ''  }
                        { this.state.activeTab === '1' || this.state.activeTab === '2'  ? <Affirmations viewAffirmations={this.props.viewAffirmations} logout={this.props.logoutUser} activeTab={this.state.activeTab} /> : '' }
                        { this.state.activeTab === '3' ? <Poll /> : '' }
                        { this.state.activeTab === '4' ? <Friends /> : '' }
                        <AffirmModal modal={this.state.modal} modalUser={this.state.modalUser} toggleModal={this.toggleModal} />
                    </Container>
                    <footer>
                        <div className="footer">
                            loving pine 2023 &copy;
                        </div>
                    </footer>
                    { this.state.activeTab === '1' || this.state.activeTab === '2' ? <Button className="affirm-button" style={{ position: 'fixed', bottom: 15, right: 15, borderRadius: 15}} onClick={() => this.toggleModal()} >send an affirmation &gt;</Button> : '' }
                </div>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => ({
    admin: state.user.admin,
    given: state.user.given,
    received: state.user.received,
    tokens: state.user.tokens,
    affirmations: state.user.affirmations,
    user: state.user,
    feed: state.feed,
});

const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(logoutUser()),
    getFeed: (token, uid, lastUpdated, toastManager) => dispatch(getFeed(token, uid, lastUpdated, toastManager)),
    viewAffirmations: (token, qid) => dispatch(viewAffirmations(token, qid))
});


export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(Main));


