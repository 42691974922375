import React, { Component } from "react";
import { connect } from "react-redux";
import { CardTitle, CardBody, CardSubtitle, Card, Button } from "reactstrap";
import { withToastManager } from 'react-toast-notifications';

import { unlockAffirmation } from "../actions/user";
import Reward from "react-rewards";
import DOMPurify from 'dompurify';

class Affirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
        }
    }

    clicked() {
        const { toastManager } = this.props;

        this.setState({ clicked: true });
        this.props.unlockAffirmation(this.props.auth.uid, this.props.affirmation.id, toastManager, this.reward)
        
        setTimeout(() => {
            this.setState({ clicked: false });
        }, 2000);
    }

    render() {
        return (
            <Card style={{ border: 'none', boxShadow: 'none', borderRadius: 0, marginLeft: 10, borderLeft: '1px solid rgba(0,0,0,0.25)' }}>
                <CardBody>
                    <CardTitle tag="h4" style={{ marginBottom: 10 }}><span className={ this.props.affirmation.locked ? 'locked' : 'unlocked' }>{ this.props.activeTab === 'received' ? this.props.affirmation.from : 'You' }</span> { this.props.activeTab === 'received' ? this.props.affirmation.senderYear : '' } said{ this.props.activeTab === 'received' ? '...' : ' to ' + this.props.affirmation.recipientName + '...' }
                    {   this.props.activeTab === 'received' &&
                        <Reward ref={(ref) => { this.reward = ref }} type='confetti'>
                            <Button key={this.props.key} disabled={ this.props.unlockLoading || !this.props.affirmation.locked } style={{ float: 'right' }} onClick={() => this.clicked() }>{ this.props.affirmation.locked ? ( this.state.clicked ? 'revealing...' : 'reveal') : 'revealed' }</Button>
                        </Reward> 
                    }
                    </CardTitle>
                    <CardSubtitle tag="p" style={{ lineHeight: 1.55 }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.affirmation.message, { ALLOWED_TAGS: ['p', 'h3', 'h4', 'strong', 'i', 'ul', 'li', 'ol', 'blockquote'] }) }} />
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    unlockLoading: state.user.unlockLoading,
});

const mapDispatchToProps = dispatch => ({
    unlockAffirmation: (uid, aid, toastManager, rewardManager) => dispatch(unlockAffirmation(uid, aid, toastManager, rewardManager)),
});

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(Affirmation));