import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import Landing from '../landing/Landing';
import { ToastContainer } from 'react-toastify';

import 'bootstrap/dist/css/bootstrap.min.css';
import Main from '../main/Main';
import Terms from '../terms/Terms';
import { logoutUser } from '../actions/user';
import SignUp from '../signup/SignUp';
import Unlock from './Unlock';
import Invited from './Invited';

const PrivateRoute = ({dispatch, component, ...rest }) => {
    if (!JSON.parse(localStorage.getItem('authenticated'))) {
        dispatch(logoutUser());
        return <Redirect to="/signup"/>
    } else {
        return (
            // return a route with all the props passed in
            <Route {...rest} render={props => (
                React.createElement(component, props)
            )}/>            
        );
    }
};

const CloseButton = ({closeToast}) => <i onClick={closeToast} className="la la-close notifications-close"/>


// convert to a functional component
const App = ({dispatch}) => {
    
    // check if the user is reg

    return (
        <BrowserRouter>
            <ToastContainer
                autoClose={5000}
                hideProgressBar
                closeButton={<CloseButton/>}
            />
            <BrowserRouter>
                <Switch>
                    <PrivateRoute path="/home" dispatch={dispatch} component={Main} />
                    <PrivateRoute path="/unlock/:id?" component={Unlock} />
                    <Route path="/landing" exact component={Landing} />
                    <Route path="/invited" component={Invited} />
                    <Route path="/signup/:id?" component={SignUp} />
                    <Route path="/tos" component={Terms} />
                    <Route path="*" component={Landing} />
                </Switch>
            </BrowserRouter>
        </BrowserRouter>
    );
};
  
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);