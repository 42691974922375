export const lightTheme = {
    body: '#f2f2f2',
    text: 'black',
    poll_shadow: '#ee45402e',
    background: '#363537',
    placeholder: '#0000001d',
    poll: '#ffffff52',
    friend_option: '#f2f2f2',
    fill: '#ee454096',
    poll_choice: '#ffffff52',
    poll_choice_border: '#ee464014',
    poll_choice_chosen_border: '#ee454052',
    poll_progress: 'linear-gradient(270deg, white, transparent, transparent)',
    devious_button: '#ee454052',
    poll_result_header_opacity: '0.3',
    poll_summary_opacity: '0.2',
    menu_option: '#ffffff',
    menu_option_hover: '#FFBDAD',
    overlay_background: '#ffffff52',
    switch_knob: '#ffffff',
    menu_background: '#ffffff',
    chosen: '#ee454052',
    card: '#ffffff52',
    switch_background: '#ee464060',
    people_opacity: '0.7',
    result_choice_border: '#0000001d',
    potential_friend_background: '#f2f2f2',
    potential_friend_color: '#ee454057',
    navbar_text: '#ee454057',
    potential_friend_border: 'rgb(230, 149, 143, 0.45)',
    timer_track: 'transparent',
    timer_path: '#ee464055',
}

export const darkTheme = {
    body: '#03001c',
    text: 'white',
    navbar_text: 'white',
    poll_shadow: 'transparent',
    placeholder: '#4d4b5f',
    poll: '#120f29',
    friend_option: '#ffffff0f',
    background: '#03001c',
    fill: 'white',
    result_choice_border: '#ffffff1f',
    poll_choice: '#232039',
    switch_knob: '#130f29',
    poll_choice_border: 'transparent',
    poll_choice_chosen_border: 'transparent',
    poll_progress: 'linear-gradient(270deg, white, transparent, transparent) !important;',
    devious_button: '#ffffff0a',
    poll_result_header_opacity: '1.0',
    poll_summary_opacity: '0.6',
    menu_option: '#232039',
    menu_option_hover: '#120f29',
    overlay_background: 'none',
    menu_background: '#37344e',
    chosen: '#9b3740',
    card: '#232039',
    switch_background: '#232039',
    people_opacity: '0.2',
    potential_friend_background: '#120f29',
    potential_friend_color: '#d0cfd4',
    potential_friend_border: 'transparent',
    tip: '#171128',
    timer_track: '#0000001f',
    timer_path: '#232039',
}