import React, { Component } from 'react';

import animatedPollGIF from '../assets/animation.gif';
import animatedAffirmationGIF from '../assets/aff.gif';
import animatedUnlockGIF from '../assets/answer.gif';

import UserOnboarding from 'react-user-onboarding';

import LPIcon from '../assets/icon.png';

class Tips extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: localStorage.getItem('proper-tutorial-completed') !== 'yes',
            tips: [
                {
                  title: <h5>a new way to <span id="flirt-crossed">flirt</span> compliment 😏</h5>,
                  text: "answer fun (and maybe cheeky) questions about students and find out what they love about you!",
                  content: <>
                    <h6>answer fun <span id="cheeky">(and maybe cheeky)</span> polls about others at Dartmouth 🌲</h6>
                    <h6>celebrate your friends and get compliments back!</h6>
                    <h6 style={{ opacity: '0.25' }}>tip: break the ice before sending that flitz 🧊🔨</h6>
                  </>,
                  showImage: true,
                },
                {
                  title: <h5>more friends, more compliments ✌️</h5>,
                  content: <>
                  <h6>adding more friends increases your visibility in polls!</h6>
                  <h6>friends stay hidden, but you'll appear in their polls.</h6>
                  <h6 style={{ opacity: '0.25' }}>tip: add a mix of students, from those you know to maybe a crush or two, be clever about it 😏</h6>
                </>,
                  showImage: true,
                },
                {
                    title: <h5>not toxic, not boring 💃</h5>,
                    content: <>
                    <h6>all polls are affirmative <span id="cheeky">(but some are cheeky)</span></h6>
                    <h6>brighten up someones day by choosing them!</h6>
                    <h6 style={{ opacity: '0.25' }}>don't worry when someone isn't chosen, we'll boost them later on so they get compliments too!</h6>
                  </>,
                    showImage: true,
                  },
                {
                  title: <h5 style={{ marginTop: '10px' }}>your visibility is { this.renderVisibility(this.props.totalFriends) }</h5>,
                    content: <>
                    <h6 style={{ marginTop: '5px' }}>{ this.renderVisibilityText(this.props.totalFriends) }</h6>
                    <h6 style={{ opacity: '0.2', marginTop: '10px' }}>reminder: add a mix of students, from those you know to maybe a crush or two, be clever about it 😏</h6>
                </>,
                showImage: false,
                }
              ],
              tipIndex: 0,
              tipsEmojis: ['🎉', '👯‍♂️', ''],
        };

        this.story = [
            {
              component: 'modal',
              intro: true,
              children: (
                <div className="tutorial-modal">
                    <div className="tutorial-modal-header">
                        <h5>welcome to loving pine! 🌲</h5>
                        <p style={{ textAlign: 'center' }}>we've prepared a short and interactive tutorial to help you familiarize yourself with the features and make the most of your experience.</p>
                    </div>
                </div>
              )
            },
            {
                component: 'modal',
                children: (
                  <div className="tutorial-modal">
                      <div className="tutorial-modal-header">
                            <h5>a new way to <span id="flirt-crossed">flirt</span> compliment 😏</h5>
                            <img className="tip-gif" src={animatedPollGIF} alt="animated poll gif" />
                            <p><span className="tip-num">#1 </span>join fun polls about Dartmouth peers</p>
                            <p><span className="tip-num">#2 </span>vote and receive answers in cheeky, slightly flirtatious polls</p>
                            <p><span className="tip-num">#3 </span>add friends & crushes for more visibility in others' polls</p>
                            <p><span className="tip-num">#4 </span>flirty votes are only seen by recipients</p>
                      </div>
                  </div>
                )
             },
             {
                component: 'modal',
                children: (
                  <div className="tutorial-modal">
                      <div className="tutorial-modal-header">
                            <h5>want to surprise someone at Dartmouth? ❤️</h5>
                            <img className="tip-gif" src={animatedAffirmationGIF} alt="animated affirmation gif" />
                            <p><span className="tip-num">#1 </span>instantly send a heartfelt affirmation to anyone at Dartmouth!</p>
                            <p><span className="tip-num">#2 </span>all affirmations are delivered to their email in seconds</p>
                            <p><span className="tip-num">#3 </span>only the recipient can ever see the affirmation</p>
                      </div>
                  </div>
                )
             },
             {
                component: 'modal',
                children: (
                  <div className="tutorial-modal">
                      <div className="tutorial-modal-header">
                            <h5>unlock compliments about you 🤩</h5>
                            <img className="tip-gif" src={animatedUnlockGIF} alt="animated affirmation gif" />
                            <p><span className="tip-num">#1 </span>unlock compliments with reveals!</p>
                            <p><span className="tip-num">#2 </span>reveals can also be used to show who sent you an affirmation</p>
                            <p><span className="tip-num">#3 </span>earn reveals by answering polls</p>
                      </div>
                  </div>
                )
             },
            {
              component: 'speech-bubble',
              children: (
                <div className="speech-bubble">
                    <h6>let's get started ✌️</h6>
                    <p>add <b>5</b> or more friends above (all connections remain anonymous, but you'll show up in their polls, so choose people you'd enjoy getting compliments from)</p>
                </div>
               )
            },
            {
              component: 'modal',
              intro: false,
              children: (
                <div className="tutorial-modal">
                      <div className="tutorial-modal-header">
                            <h5>some final thoughts 💭</h5>
                            <p style={{ textAlign: 'center' }}>dive into this playful, flirtatious adventure with wit and charm and start with a wholesome foundation before turning up the heat. <br /><br />expand your social circle, engage in cheeky polls, and let the compliments work their magic. <br /><br />have fun! - loving pine team 🌲</p>
                      </div>
                  </div>
              )
            }
          ]      
    }

    hideTutorial = () => {
        // set a cookie so that the tutorial doesn't show up again
        // in localStorage, set a key called "tutorial" to true
        localStorage.setItem("proper-tutorial-completed", "yes");
        this.setState({ showModal: false });
    }

    renderVisibilityText = (totalFriends) => {
        if (totalFriends <= 2) {
          return "you cannot be seen by many at Dartmouth, so add friends so they see you in their polls!"
        } else if (totalFriends <= 5) {
            return "you can only be seen by some at Dartmouth, so add more friends to be in their polls!"
        } else if (totalFriends <= 10) {
            return "you can been seen by many at Dartmouth, but add more friends to be in their polls!"
        } else {
            return "wow, you're so facetimey 💕, so keep on adding until you are in everyone's polls! 😈"
        }
      }
    
      renderVisibility = (totalFriends) => {
        if (totalFriends <= 2) {
            return "low 😞"
        } else if (totalFriends <= 5) {
            return "moderate 🙆‍♀️"
        } else if (totalFriends <= 10) {
            return "strong 🔥"
        } else {
            return "superstar 🤩"
        }
      }


    render() {
        return (
            <UserOnboarding
                story={this.story}
                isVisible={this.state.showModal}
                onClose={() => this.hideTutorial()}
            />
        );
    }
}

export default Tips;