// create a component which displays a feed of all poll results
// which attaches onto the cache table in firebase and updates in real time

// better loading for feed (pull to refresh and auto refresh)
// more contrast in tutorial
// no affirmations empty state
// better tutorial
// add like button
// new launch 

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import BezierCurve from './BezierCurve';
import moment from 'moment';
import { HiHeart, HiOutlineHeart, HiMail } from 'react-icons/hi';
import firebase from 'firebase/compat/app';
import { withToastManager } from 'react-toast-notifications';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { likeVote } from '../actions/user';

class Feed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'everyone',
            opened: {},
            liked: {},
            totalLikes: {}
        }
    }


    timeAgo(timestamp) {
        const now = moment();
        const then = moment(timestamp);
        const diffInHours = now.diff(then, 'hours');

        if (diffInHours >= 24) {
            return 'earlier on';
        } else if (diffInHours >= 1) {
            return then.fromNow(true) + ' ago';
        } else {
            return then.fromNow();
        }
    }

    fetchOptions = (query) => {
        return fetch(`https://api-lookup.dartmouth.edu/v1/lookup?q=${query}&includeAlum=false`).then(res => {
            return res.json();
        }).then(json => {
            return json.users.map(user => {
                return {
                    value: user.uid,
                    label: user.displayName,
                    email: user.mail
                }
            })
        });
    }

    render() {
        let feed = [];

        const loadingSkeleton = (
            <SkeletonTheme baseColor="#ebebeb17" highlightColor="#f5f5f503">
                <div className='friend-loading'>
                    <div className='friend-info-loading'>
                        <Skeleton width={230} /><br />
                        <Skeleton />
                    </div>
                    <div className='friend-actions-loading'>
                        <Skeleton circle height={50} width={50}/>
                    </div>
                </div><br /><br />
                <div className='friend-loading'>
                    <div className='friend-info-loading'>
                        <Skeleton width={230} /><br />
                        <Skeleton />
                    </div>
                    <div className='friend-actions-loading'>
                        <Skeleton circle height={50} width={50}/>
                    </div>
                </div>
                <br /><br />
                <div className='friend-loading'>
                    <div className='friend-info-loading'>
                        <Skeleton width={230} /><br />
                        <Skeleton />
                    </div>
                    <div className='friend-actions-loading'>
                        <Skeleton circle height={50} width={50}/>
                    </div>
                </div>
            </SkeletonTheme>
        );

        // if the feed is loaded, we can start processing it
        if (this.props.feed.loaded) {
            let currentVoter = '';
            let currentVoterYear = '';
            let currentVoterLastTimestamp = 0;
            let totalLikes = 0;
            let currentVoterId = '';
            let currentVotes = [];

            const feedContainer = this.state.activeTab === 'friends' ? this.props.feed.feed : this.props.feed.global;

            feedContainer.forEach((item, _) => {
                if (item.voter === currentVoter) {
                    totalLikes += (item.total_likes ? item.total_likes : 0);
                    currentVotes.push(item);
                } else {
                    feed.push({
                        voter: currentVoter,
                        total_likes: totalLikes,
                        voter_id: currentVoterId,
                        voter_year: currentVoterYear,
                        timestamp: currentVoterLastTimestamp,
                        votes: currentVotes
                    });
                    currentVoter = item.voter;
                    currentVoterYear = item.voter_year;
                    currentVoterId = item.voter_id;
                    currentVoterLastTimestamp = item.timestamp;
                    totalLikes = item.total_likes ? item.total_likes : 0;
                    currentVotes = [item];
                }
            }
            );

            // add the last voter
            feed.push({
                voter: currentVoter,
                votes: currentVotes,
                voter_year: currentVoterYear,
            });

            // remove the first item, which is empty
            feed.shift();
        }

        // let lastUpdated be a human readable time from this.state.lastUpdated
        return (
            <Container>
                <Row style={{ padding: '0px !important' }}>
                        <div className="feed-header">
                            <div className="feed-header-text">
                                <h4 className="guide-title">what's been happening...</h4>
                                <h5 className="guide-subtitle">only wholesome votes are shown</h5>
                            </div>
                            <div className="feed-header-action">
                                <h3 className={this.state.activeTab === 'friends' ? "active-tab" : ""} onClick={() => this.setState({ activeTab: 'friends' })}>friends</h3>
                                <h3 className={this.state.activeTab === 'everyone' ? "active-tab" : ""} onClick={() => this.setState({ activeTab: 'everyone' })}>everyone</h3>
                            </div>
                        </div>
                        <div className='your-summary'>
                            <h4>your summary</h4>
                            <h3 className="guide-super"><span id="total-polls">{ this.props.user.total_polls ? this.props.user.total_polls : 0 }</span> polls answered by you 🙇‍♀️</h3>
                            <h3 className="guide-super"><span className='total-hearts'>{ this.props.feed.total_likes_on_your_choices ? this.props.feed.total_likes_on_your_choices[0].total : 0 }</span> upvotes on your choices 👏</h3> 
                            <h3 className="guide-super"><span className='total-hearts'>{ this.props.feed.total_likes_about_you ? this.props.feed.total_likes_about_you[0].total : 0 }</span> upvotes on votes about you! ❤️</h3>
                        </div>
                        <ul className="feed-ul">
                            { this.props.feed.loaded ? feed.map((item, rootIndex) => (
                                <div key={rootIndex}>
                                    <li className={"feed-box " + ((item.voter.toLowerCase() === this.props.user.username.toLowerCase()) ? 'your-item' : '')}>
                                        <div className="feed-data">
                                            <span className="voter"> {item.voter}, <b>{ item.voter_year }</b></span>
                                            <span className="num-votes">voted in <span className="poll-count">{ item.votes.length }</span> poll{ item.votes.length > 1 ? 's' : '' } <span className={(this.timeAgo(item.timestamp) === 'a few seconds ago' || this.timeAgo(item.timestamp).includes('minute')) ? 'new-sugg' : ''}>{ this.timeAgo(item.timestamp) }</span></span>
                                        </div>
                                        <div className="feed-action">
                                            <section className="mail">
                                                <button className="mail-btn mail-btn-tetrary" onClick={(e) => {
                                                    e.preventDefault();

                                                    this.fetchOptions(item.voter_id).then(users => {
                                                        let result = users[0];
                                                        const modalUser = {
                                                            recipientName: result.label,
                                                            recipientEmail: result.email,
                                                            to: result.value
                                                        }                                                                

                                                        this.props.toggleModal(e, modalUser);
                                                    });

                                                }}><HiMail /></button>
                                            </section> 
                                            <span className="totalHearts">
                                                { this.state.totalLikes[rootIndex] !== undefined ? this.state.totalLikes[rootIndex].total_likes : item.total_likes } <HiHeart />
                                            </span>
                                        </div>
                                    </li>
                                    <ul className="answer-ul">
                                        <div className='feed-item'></div>
                                        { (this.state.opened[rootIndex] === undefined ? item.votes.slice(0, 3) : item.votes).map((vote, index) => (
                                            <div key={index} className='feed-item'>
                                                <div className="arrow-box">
                                                    <BezierCurve />
                                                </div>
                                                <li key={index} className="vote-box">
                                                    <div className="vote-data">
                                                        <div className="vote-info">
                                                            <span className="vote-type">"{ vote.question }"</span>
                                                            <span className="vote-time">{ this.timeAgo(vote.timestamp) }</span>
                                                        </div>
                                                        <div className="vote-answer-box">
                                                            <span className="votee">
                                                                <span className="answer">{ item.voter.toLowerCase() === this.props.user.username.toLowerCase() ? 'you' : item.voter.split(" ")[0] } voted for...</span><br />
                                                                { vote.votee }, { vote.votee_year }
                                                            </span>
                                                            { (item.voter.toLowerCase() !== this.props.user.username.toLowerCase()) ? <section className="like">
                                                                {
                                                                    vote.liked || this.state.liked[vote.relationship_id] !== undefined ? 
                                                                        <span className='totalLikes'>{ this.state.liked[vote.relationship_id] !== undefined ? this.state.liked[vote.relationship_id].total_likes  : vote.total_likes } <HiHeart /></span>
                                                                    :
                                                                        <span className='totalLikes unliked' onClick={() => {
                                                                            this.setState({ 
                                                                                liked: { ...this.state.liked, [vote.relationship_id]: { total_likes: vote.total_likes + 1 } },
                                                                                totalLikes: { ...this.state.totalLikes, [rootIndex]: { total_likes: (this.state.totalLikes[rootIndex] !== undefined ? this.state.totalLikes[rootIndex].total_likes + 1 : item.total_likes + 1 ) } }
                                                                            });

                                                                            firebase.auth().currentUser.getIdToken().then((token) => {
                                                                                this.props.likeVote(token, vote.relationship_id)
                                                                            });
                                                                        }}>{ vote.total_likes } <HiOutlineHeart /></span>
                                                                }
                                                            </section> : ((vote.total_likes > 0) ? <span className='totalLikes'>{ this.state.liked[vote.relationship_id] !== undefined ? this.state.liked[vote.relationship_id].total_likes  : vote.total_likes } <HiHeart /></span> : '') }
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                        )) }
                                        { this.state.opened[rootIndex] === undefined && item.votes.length - item.votes.slice(0, 3).length > 0 ? <div key={rootIndex} onClick={() => this.setState({ opened: { ...this.state.opened, [rootIndex]: true } })} className='feed-item'>
                                            <div className="arrow-box">
                                                <BezierCurve />
                                            </div>
                                            <li className="vote-box more">
                                                <div className="vote-data">
                                                    <div className="more-info">
                                                        <span className="vote-type">see { item.votes.length - item.votes.slice(0, 3).length } more answer{ item.votes.length - item.votes.slice(0, 3).length > 1 ? 's' : '' }...</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </div> : '' }
                                    </ul>
                                </div>
                            )) : loadingSkeleton }
                        </ul>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    feed: state.feed,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    likeVote: (token, relationship_id) => dispatch(likeVote(token, relationship_id)),
});

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(Feed));