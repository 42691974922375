// make an invited component that will take the id in the url, dispatch logInvite and then redirect to the signup page
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logInvite } from '../actions/user';

class Invited extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sent: false
        }
    }

    render() {
        return (
            <div>
                <h1>Redirecting...</h1>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    logInvite: id => dispatch(logInvite(id))
});

export default connect(null, mapDispatchToProps)(Invited);
