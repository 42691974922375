import React, { Component } from "react";
import { connect } from 'react-redux';
import { BsChevronDown } from "react-icons/bs";
import firebase from 'firebase/compat/app';
import Collapsible from 'react-collapsible';

import { Button, CardTitle, CardBody, CardSubtitle, Card, Navbar, NavbarBrand, NavbarText } from "reactstrap";
import Affirmation from "./Affirmation";

class Affirmations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            tab: 'received',
            questions: [
                { label: 'the moment we became friends...', value: 0 },
                { label: 'our best freshmen year memory...', value: 1 },
                { label: 'hardest class we faced at Dartmouth...', value: 2 },
                { label: 'the thing I\'m most proud of...', value: 3 },
                { label: 'the reason I admire you most...', value: 4 },
                { label: 'the one thing on our bucket list...', value: 5 },
                { label: 'the thing you will be the most famous for...', value: 6 },
                { label: 'the thing I love about you the most...', value: 7 },
                { label: 'i\'ll never forget when you helped me with...', value: 8 },
                { label: "The time we survived a mid-term crisis...", value: 9 },
                { label: "The most memorable road trip or weekend getaway...", value: 10 },
                { label: "The best spring break adventure we shared...", value: 11 },
                { label: "The moment we knew we could rely on each other...", value: 12 },
                { label: "Our go-to stress-relief activity on campus...", value: 13 },
                { label: "The thing that always makes us laugh when we're together...", value: 14 },
            ],
            opened: { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false, 10: false, 11: false, 12: false, 13: false, 14: false }
        };
    }

  render() {
    let given = this.props.given ? Object.keys(this.props.given).reduce((acc, key) => acc + this.props.given[key].length, 0) : 0;
    let received = this.props.received ? Object.keys(this.props.received).reduce((acc, key) => acc + this.props.received[key].length, 0) : 0;
    const affirmationStore = Object.keys(this.state.tab === "received" ? this.props.received : this.props.given)

    return (
        <div className="affirmations">
            <div className="affirmations-header">
                <h3 className={(this.state.tab === "received") ? "active-tab" : ""} onClick={() => this.setState({ tab: 'received' }) }>received ({ received })</h3>
                <h3 className={(this.state.tab === "sent") ? "active-tab" : ""} onClick={() => this.setState({ tab: 'sent' })}>sent ({ given })</h3>
            </div>

            
            {
            this.props.given && this.props.received ? (
                // sort the affirmationStore by their unseen count descending
                affirmationStore.map((qid, index1) => {
                    const info = this.state.tab === "received" ? this.props.received : this.props.given;
                    
                    let unseen = info[qid].reduce((acc, affirmation) => {
                        if (!affirmation.seen && affirmation.seen !== undefined) {
                            return acc + 1;
                        }
                        return acc;
                    }, 0);

                    return (
                        <Collapsible 
                            trigger={
                            [<Card key={qid} style={{ marginBottom: 20 }} onClick={() => { 
                                if (!this.state.opened[qid] && unseen > 0) {
                                    if (firebase.auth().currentUser) {
                                        firebase.auth().currentUser.getIdToken().then((token) => {
                                            this.props.viewAffirmations(token, qid)
                                            this.setState({ opened: { ...this.state.opened, [qid]: true } })
                                        }).catch((error) => {
                                            console.log(error)
                                        })
                                    }
                                }
                                }}>
                                <CardBody>
                                    { this.state.tab === "received" && unseen > 0 ? (
                                        <div className="affirm-notification">
                                            <span className="affirm-locked-number">{ unseen }</span>
                                        </div> 
                                    ) : '' }
                                    <CardTitle tag="h3">{ this.state.questions[qid].label.toLowerCase() }</CardTitle>
                                    <CardSubtitle className="mb-2 text-muted" tag="h6">{ info[qid].length } affirmation{ info[qid].length > 1 ? 's' : '' } { this.state.tab === "sent"? 'given' : '' }</CardSubtitle>
                                </CardBody>
                                <BsChevronDown />
                            </Card>]
                        } key={index1} overflowWhenOpen={'visible'}>
                        { 
                        // sort info qid by having the unseen affirmations first, then keep locked at the top

                        info[qid].sort((a, b) => {
                            if (a.locked && !b.locked) {
                                return -1;
                            } else if (!a.locked && b.locked) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }).map((affirmation, index2) => {
                            return (
                                <Affirmation activeTab={this.state.tab} affirmation={affirmation} key={index1 + '-' + index2} />
                            )
                        })}<br />
                    </Collapsible>
                    )
                }))
            :
            null
            }
 
            {    // generate empty cards for questions that have no affirmations
                this.props.activeTab === '1' && this.state.questions.map((question, index) => {
                    if (!this.props.received || !this.props.received[question.value]) {
                        return (
                            <Card key={index} style={{ marginBottom: 20, opacity: 0.4 }}>
                                <CardBody>
                                    <CardTitle tag="h3">{ this.state.questions[index].label.toLowerCase() }</CardTitle>
                                    <CardSubtitle className="mb-2 text-muted" tag="h6">no affirmations yet</CardSubtitle>
                                </CardBody>
                            </Card>
                        )
                    }

                    return null;
                })
            }
            <Navbar expand="md">
                <NavbarBrand href="/">
                    <h6 style={{ opacity: 0.5 }}>only you can see your affirmations</h6>
                </NavbarBrand>
                <NavbarText style={{ textAlign: 'right' }}>
                    <Button onClick={() => this.props.logout()}>log out &gt;</Button>
                </NavbarText>
            </Navbar>
        </div>
    );
  }
}

const mapStateToProps = state => ({
    received: state.user.received,
    given: state.user.given,
    auth: state.firebase.auth
});

export default connect(mapStateToProps)(Affirmations);