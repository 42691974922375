import React, { Component } from "react";
import { connect } from "react-redux";
import { Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarText } from 'reactstrap';
import { BsFillTreeFill } from 'react-icons/bs';
import { HiHeart } from 'react-icons/hi';
import { HiMail } from 'react-icons/hi';
import { IoPeople } from 'react-icons/io5';
import { AiFillHome } from 'react-icons/ai';
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert'; // Import
import gameStart from '../assets/game.mp3';
import gameWin from '../assets/win.mp3';
import bite from '../assets/bite.mp3';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bug: '',
            bugPlaying: false,
            fire: [],
            delay: 100,
            snacks: 0,
            bugSize: 40,
            emojis: [],
            bugsAttacking: true,
            showEmojiCursor: false,
        };
    }

    bugAttack = () => {
        if (this.state.bug.length <= 40 && this.state.bugsAttacking) {
            // let bug be a random bug
            let bug = '🐛';
            this.setState({ bug: this.state.bug + bug, delay: this.state.delay * 2 });
        } else {
            this.setState({ bugsAttacking: false });
            clearInterval(this.bugTimer);
        }
    }

    updateFoodPositions = () => {
        // for each emoji, update its position with a small velocity and change their x and y

        this.setState((prevState) => ({
            emojis: prevState.emojis.map((emoji) => {
                // if the emoji hits a wall, reverse its direction
                let newX = emoji.x;
                let newY = emoji.y;
                let xVelocity = emoji.xVelocity;
                let yVelocity = emoji.yVelocity;

                if (emoji.x > window.innerWidth - 50) {
                    newX = window.innerWidth - 50;
                    xVelocity = -xVelocity;
                } else if (emoji.x < 0) {
                    newX = 0;
                    xVelocity = -xVelocity;
                } else {
                    newX = emoji.x + xVelocity;
                }

                if (emoji.y > window.innerHeight - 50) {
                    newY = window.innerHeight - 50;
                    yVelocity = -yVelocity;
                } else if (emoji.y < 0) {
                    newY = 0;
                    yVelocity = -yVelocity;
                } else {
                    newY = emoji.y + yVelocity;
                }

                return { ...emoji, x: newX, y: newY, xVelocity, yVelocity };
            }),
        }));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.bugPlaying !== this.state.bugPlaying) {
          this.setEmojiCursorStyle(this.state.bugPlaying);
      
          if (this.state.bugPlaying) {
            this.positions = setInterval(this.updateFoodPositions, 100);
            this.timer = setInterval(this.spawnFoodEmoji, 500);

            if (this.state.bugsAttacking) {
              this.bugTimer = setInterval(this.bugAttack, this.state.delay);
            } else {
                clearInterval(this.bugTimer);
            }
          } else {
            this.setState({ emojis: [], bug: '', fire: [] });
            clearInterval(this.timer);
          }
        }
      }
      
      componentWillUnmount() {
        clearInterval(this.timer);
      }

    spawnFoodEmoji = () => {
        if (this.state.emojis.length >= 50) {
            return;
        }

        const foodEmojis = ['🍕', '🍔', '🌮', '🍣', '🥗', '🍩', '🍦'];

        const randomEmoji = foodEmojis[Math.floor(Math.random() * foodEmojis.length)];
        let randomX = Math.floor(Math.random() * window.innerWidth);
        let randomY = Math.floor(Math.random() * window.innerHeight);

        // random number either 1 or -1
        let randomVelocity = Math.random() < 0.5 ? -1 : 1;

        this.setState((prevState) => ({
          emojis: [...prevState.emojis, { id: Date.now(), value: randomEmoji, x: randomX, y: randomY, xVelocity: 5 * randomVelocity, yVelocity: 5 * randomVelocity  }],
        }));
      }

      handleEmojiHover = (emojiId) => {
        // incremnet the snacks if the emoji is food

        let snackIncrement = 0;
        let sizeIncrement = 0;
        let newBugs = this.state.bug;
        if (['🍕', '🍔', '🌮', '🍣', '🥗', '🍩', '🍦'].includes(this.state.emojis.find((emoji) => emoji.id === emojiId).value)) {
            snackIncrement = 1;
            sizeIncrement = 5;
            newBugs = this.state.bug.slice(0, -2);
            new Audio(bite).play();
        }

        if (this.state.bugSize + sizeIncrement > 120) {
            sizeIncrement = 0;
        }

        // replace the last emoji in this.state.bug with skull

        this.setState((prevState) => ({
          emojis: prevState.emojis.map((emoji) =>
            emoji.id === emojiId ? { ...emoji, value: '💩' } : emoji
          ),
          snacks: this.state.snacks + snackIncrement,
          bugSize: this.state.bugSize + sizeIncrement,
          bug: newBugs,

        }), () => {
            // set the cursor to the bug
            if (this.state.snacks === 20) {
                this.setState({ bugPlaying: false });
                this.setEmojiCursorStyle(false);
                new Audio(gameWin).play();
                confirmAlert({
                    title: 'phew...that was close!',
                    message: "you saved the day! if you've got feature requests or notice any real bugs, reach out to team@lovingpine.org and we'll be happy to squash them!",
                    buttons: [
                      {
                        label: 'okay',
                      }
                    ]
                  });
            } else {
                this.setEmojiCursorStyle(true);
            }
        })
      }

    setEmojiCursorStyle(enabled) {
        const emojiCursorClassName = 'emoji-cursor';
      
        if (enabled) {
          const style = document.createElement('style');
          style.id = emojiCursorClassName;
          style.innerHTML = `
            * {
              cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${this.state.bugSize}" height="${this.state.bugSize}" viewBox="0 0 ${this.state.bugSize} ${this.state.bugSize}"><text y="50%" x="50%" dy=".3em" font-size="${this.state.bugSize}" text-anchor="middle" fill="black">🐛</text></svg>') ${this.state.bugSize/2} ${this.state.bugSize/2}, auto !important;
            }
          `;
          document.head.appendChild(style);
        } else {
            const style = document.createElement('style');
            style.id = emojiCursorClassName;
            style.innerHTML = `
              * {
                cursor: auto !important;
                }
            `;
            document.head.appendChild(style);
        }
      }

  render() {
    let given = this.props.given ? Object.keys(this.props.given).reduce((acc, key) => acc + this.props.given[key].length, 0) : 0;
    let received = this.props.received ? Object.keys(this.props.received).reduce((acc, key) => acc + this.props.received[key].length, 0) : 0;

    // let received_new be the set of affirmations which have the field seen = false
    let received_new = this.props.received ? Object.keys(this.props.received).reduce((acc, key) => acc + this.props.received[key].filter((affirmation) => affirmation.seen === false).length, 0) : 0;

    let new_notifications = 0;

    // let last seen be the current time
    let last_seen_notifications = this.props.user.last_login ? this.props.user.last_login._seconds * 1000 : 0;

    if (this.props.feed.loaded) {
        this.props.feed.feed.forEach((item, index) => {
           // compare using moment
              if (moment(item.timestamp).isAfter(last_seen_notifications)) {
                new_notifications++;
              }
        });
    }

    if (new_notifications === 0) {
        new_notifications = 'new';
    }

    let student_name = this.props.user.username && this.props.user.username.toLowerCase();

    if (student_name === 'penelope l. tir') {
        student_name = 'silly bee 🐝';
        document.body.style.cursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><text y="50%" x="50%" dy=".3em" font-size="30" text-anchor="middle" fill="black">🐛</text></svg>') 20 20, auto`
    }

    // for each affirmation in received, if it 

    return (
      <div className="header">
        <Navbar expand="md">
            <NavbarBrand onClick={() => this.setState({ fire: [...this.state.fire, <span style={{ height: 5, width: 5, fontSize: '15px',  position: 'absolute', left: Math.floor(Math.random() * 21) - 5, top: Math.floor(Math.random() * 26) - 5 }}>🔥</span>] }) }>
                <h2 style={{ position: 'relative' }}>{ this.state.fire }<BsFillTreeFill style={{marginRight: 10, width: 25, marginBottom: 5}} /></h2>
            </NavbarBrand>
            <NavbarText style={{ textAlign: 'right', marginLeft: 'auto' }}>
                <h4 style={{ margin: 0 }} onClick={() => { 
                    if (!this.state.bugPlaying) {
                        new Audio(gameStart).play();
                    }

                    this.setState({ bugPlaying: !this.state.bugPlaying, bugSize: 40, snacks: 0 })}
                }>
                    { this.props.user.username && !this.state.bugPlaying ? student_name : (this.state.bugPlaying ? "oh no " + this.props.user.username.toLowerCase().split(" ")[0] + "! our software bugs are escaping! " : '') }
                    { this.state.bugPlaying ? <br /> : '' }
                    { this.state.bug }
                </h4>
                { !this.state.bugPlaying ? <p style={{ margin: 0 }}>
                    received { received } and given { given } affirmations
                </p> : <><p style={{ margin: 0 }}>appease the bugs by feeding them snacks!</p><p>total snacks fed: <h2>{ this.state.snacks }<span style={{ opacity: 0.3 }}>/20</span></h2></p></> }
                <span className="tokens" style={{ opacity:0.5, fontSize: '0.85em', position: 'relative', top: '-3px' }}>{ this.props.tokens } reveal{ this.props.tokens !== 1 ? 's' : '' } remaining</span>
            </NavbarText>
        </Navbar>
        <Nav fill pills>
            {
            <NavItem onClick={() => {
                this.props.toggle('5')
                }}>
                <NavLink style={{ position: 'relative' }} active={this.props.activeTab === '5'} href="#">
                    <AiFillHome />
                    { this.props.activeTab !== '5' && (new_notifications > 0 || new_notifications === 'new') ? <span className="notifications">{ new_notifications }</span> : '' }
                </NavLink>
            </NavItem>
            }
            {
            <NavItem onClick={() => this.props.toggle('3')}>
                <NavLink active={this.props.activeTab === '3'} href="#">
                    <HiHeart />
                </NavLink>
            </NavItem>
            }
            <NavItem style={{ position: 'relative' }} onClick={() => this.props.toggle('2')}>
                <NavLink active={this.props.activeTab === '2'} href="#">
                    <HiMail />
                    { this.props.activeTab !== '2' && (received_new > 0) ? <span className="notifications">{ received_new }</span> : '' }
                </NavLink>
            </NavItem>
            <NavItem onClick={() => this.props.toggle('4')}>
                <NavLink active={this.props.activeTab === '4'} href="#">
                    <IoPeople />
                </NavLink>
            </NavItem>
        </Nav>
        <div className="food">
        {this.state.emojis.map((emoji) => (
        <span
          key={emoji.id}
          style={{ textShadow: '0px 0px 5px #8e2933', position: 'absolute', left: emoji.x, top: emoji.y, fontSize: emoji.value === '💩' ? '15px' : '30px', zIndex: 1000 }}
          onMouseOver={() => this.handleEmojiHover(emoji.id)}
        >
          {emoji.value}
        </span>
        ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    user: state.user,
    feed: state.feed,
});


export default connect(mapStateToProps)(Header);