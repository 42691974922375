import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BsStars, BsCheck } from 'react-icons/bs';
import { MdOutlineManageSearch } from 'react-icons/md';
import { LoaderDots } from '@thumbtack/thumbprint-react';
import { Spinner, Container, Button } from 'reactstrap';

// import PullToRefresh from 'react-simple-pull-to-refresh';
import debounce from 'debounce-promise';
import firebase from 'firebase/compat/app';

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { addFriend, removeFriend, getSuggestions } from '../actions/user';
import { withToastManager } from 'react-toast-notifications';

class FriendFinder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '',
            addingFriendUid: null,
            tab: 0,
            searchResults: [],
            searching: false,
        }
    }
    
    fetchOptions = (query) => {
        this.setState({ query: query.target.value, tab: 1 });

        if (query.target.value.length === 0) {
            this.setState({ tab: 0, searchResults: [] });
            // return a promise which resolves to []
            return Promise.resolve([]);
        }

        // replace spaces with %20    
        this.setState({ searching: true });

        return fetch(`https://api-lookup.dartmouth.edu/v1/lookup?q=${encodeURIComponent(query.target.value)}&includeAlum=false&field=dcDeptclass&field=uid&field=displayName&field=eduPersonPrimaryAffiliation`).then(res => {
            return res.json();
        }).then(json => {
            const current_friends = this.props.user.friends ? this.props.user.friends : [];
            
            const updated_results = json.users.map(user => {
                // check if the user is already a friend (the user.uid exists in the current_friends array of objects with a uid property named value)
                if (!current_friends.find(friend => friend.value === user.uid)) {
                  return {
                      value: user.uid,
                      label: user.displayName,
                      affiliation: user.eduPersonPrimaryAffiliation,
                      friend: false,
                      class: user.dcDeptclass,
                  }
                } else {
                  return {
                    value: user.uid,
                    label: user.displayName,
                    affiliation: user.eduPersonPrimaryAffiliation,
                    friend: true,
                    class: user.dcDeptclass,
                  }
                }
            })
                
            // filter out the users that are not students
            const filtered_results = updated_results.filter(user => user.affiliation === 'Student' || user.affiliation === 'Alum');
    
            // order users by year
            const sorted_results = filtered_results.sort((a, b) => {
                // year is a string which could be: 'XX, two letters, or empty
                // if it's empty, we want to put it at the end
                // try and cast it to a number, if it fails, put it at the end
    
                // remove ' from the year
                const aYear = parseInt(a.class ? a.class.replace(/'/g, '') : 0);
                const bYear = parseInt(b.class ? b.class.replace(/'/g, '') : 0);
    
                // if aYear is not a number, put it at the end
                if (isNaN(aYear)) {
                    return 1;
                }
    
                // if bYear is not a number, put it at the end
                if (isNaN(bYear)) {
                    return -1;
                }
    
                return aYear - bYear;
            });
    
            this.setState({ searching: false, searchResults: sorted_results });
        }).catch(err => {
            console.log(err);
        });
    }

    handleChange = (e) => {
        if (e.target.value.length > 1) {
            this.fetchOptions(e.target.value);
        } else {
            this.setState({ searchResults: [], tab: 0 });
        }
    }

    deleteFriend = (friend) => {
        firebase.auth().currentUser.getIdToken().then((token) => {
          this.setState({ animatingText: false });
          this.props.removeFriend(token, friend);
        });
    }

    addNewFriend(friend) {
        const { toastManager } = this.props;
        firebase.auth().currentUser.getIdToken().then((token) => {
          this.props.addFriend(token, friend, toastManager);
        });
    }

    refreshSuggestions = () => {
        const { toastManager } = this.props;
        return new Promise((resolve) => {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getSuggestions(this.props.auth.uid, token, true, toastManager);
            });
            setTimeout(() => {
                resolve();
            }
            , 2000);
        });
    }

    render() {
        const fetchOpts = debounce(this.fetchOptions, 273);
        const noResults = <div className="no-results">{ this.state.tab === 0 ? 'no suggestions, add some friends to get some!' : 'no results, try being more specific!' }</div>;

        const containerStyle = { height: '600px', overflow: 'scroll', paddingBottom: '400px', opacity: this.state.searching ? '0.4' : '1.0', maxWidth: '1000px !important' };
        const addFriendStyle = {
            fontSize: '2em', 
            padding: '0px 18px', 
            marginLeft: '15px',
            borderRadius: '15px',
            width: '50px',
            height: '50px',
            opacity: '1.0'
        }

        const loadingSkeleton = (
            <SkeletonTheme baseColor="#ebebeb17" highlightColor="#f5f5f503">
                <div className='friend-loading'>
                    <div className='friend-info-loading'>
                        <Skeleton width={230} /><br />
                        <Skeleton />
                    </div>
                    <div className='friend-actions-loading'>
                        <Skeleton circle height={50} width={50}/>
                    </div>
                </div><br /><br />
                <div className='friend-loading'>
                    <div className='friend-info-loading'>
                        <Skeleton width={230} /><br />
                        <Skeleton />
                    </div>
                    <div className='friend-actions-loading'>
                        <Skeleton circle height={50} width={50}/>
                    </div>
                </div>
                <br /><br />
                <div className='friend-loading'>
                    <div className='friend-info-loading'>
                        <Skeleton width={230} /><br />
                        <Skeleton />
                    </div>
                    <div className='friend-actions-loading'>
                        <Skeleton circle height={50} width={50}/>
                    </div>
                </div>
            </SkeletonTheme>
        );

        let searchContainer = this.state.tab === 1 ? this.state.searchResults : (
            this.props.user.suggestions ? this.props.user.suggestions : []
        );
        
        const searchResults = searchContainer.length > 0 ? searchContainer.map((result, index) => {
                const isFriend = this.props.user.friends.find(friend => friend.value === result.value);
                
                return (
                    <div className='friend' key={index}>
                        <div className='friend-info'>
                            <h5 style={{ fontWeight: isFriend ? 'bold' : 'normal' }}>{ result.label.toLowerCase() }, <span className='class-year'>{ result.class }</span>{ result.new ? <span className='new-sugg'>new!</span> : ''}</h5>
                            <p style={{ opacity: 0.55 }}>you've sent them no affirmations</p>
                        </div>
                        <div className='friend-actions'>
                            { !isFriend ? <Button 
                                style={{...addFriendStyle, opacity: this.props.user.toBeAdded[result.value] ? '0.5' : '1.0'}}
                                disabled={this.props.user.toBeAdded[result.value]}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.addNewFriend(result);
                                }}
                            >
                                <span style={this.props.user.toBeAdded[result.value] ? {} : { position: 'relative', top: -4 }}>
                                    { this.props.user.toBeAdded[result.value] ? <Spinner size="sm" /> : '+' }
                                </span>
                            </Button> : <BsCheck style={{ marginRight: 10 }} /> }
                        </div>
                    </div>
                );
        }) : noResults;
        
        return (
            <div className='friend-box'>
                <Tooltip id="my-tooltip" />
                <input className="friend-search" type="text" value={this.state.value} placeholder="search for students here!" onChange={fetchOpts} />
                { this.state.searching ? <LoaderDots color="inverse" size="small" /> : '' }
                <div className="search-status">
                    <div className={"suggestions-status " + (this.state.tab === 0 ? "status-active" : "")} onClick={() => this.setState({ tab: 0 })}>
                        <h6>{ this.props.user.suggestions.length > 0 ? this.props.user.suggestions.length : 'no' } suggestion{ this.props.user.suggestions.length > 0 || this.props.user.suggestions.length === 0 ? 's' : '' } { !this.props.user.suggestionsLoading ? <BsStars /> : <Spinner style={{ position: 'relative', top: '1px', left: '5px' }} size="sm" /> }</h6>
                    </div>
                    <div className={"results-status " + (this.state.tab === 1 ? "status-active" : "")} style={{ opacity: this.state.searchResults.length === 0 ? '0.5' : '1.0' }} onClick={() => this.setState({ tab: 1 })}>
                        <h6>{ this.state.searching ? 'searching...' : (this.state.searchResults.length > 0 ? this.state.searchResults.length  : 'no') + ' result' + (this.state.searchResults.length > 1 || this.state.searchResults.length === 0 ? 's' : '') } <MdOutlineManageSearch /></h6>
                    </div>
                </div>
                <Container style={containerStyle}>
                    { this.state.searching ? loadingSkeleton : searchResults }
                </Container> 
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    addFriend: (token, friend, toastManager) => dispatch(addFriend(token, friend, toastManager)),
    removeFriend: (token, friend) => dispatch(removeFriend(token, friend)),
    getSuggestions: (token) => dispatch(getSuggestions(token))
});

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(FriendFinder));