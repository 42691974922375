import React, { Component } from "react";

import { connect } from "react-redux";
import CountUp from 'react-countup';

import firebase from 'firebase/compat/app';

import { getPoll, revealName } from "../actions/user";

import Reward from "react-rewards";

class PollResults extends Component {
    render() {  
        const user_scores = this.props.user.scores ? this.props.user.scores : [];

        // order the scores by the number of people who chose it, the .chosen field
        const sorted_scores = user_scores.sort((a, b) => {
            return b.chosen - a.chosen;
        });

        
        const score_board = sorted_scores.map((score, index) => {
            return (
                <div key={index} className="result">
                    <div className="result-header">
                        <h1><CountUp start={1} duration={1.5} easingFn={(t, b, c, d) => {t /= d;t--;return -c * (t*t*t*t - 1) + b;}}
                        end={score.chosen}/> <span className="people">student{ score.chosen > 1 ? 's' : '' } said...</span></h1>
                    </div>
                    <h2>{ score.question }</h2>
                    <div className="result-details">
                        <div className="result-breakdown">
                            <h5>
                                <span className="chosen-by-title">chosen by</span>
                                { score.chosen_by.map((person, chosen_by_index) => {
                                    
                                    // user clicks -> send chosen_by_id and qid to server
                                    // server checks if enough token
                                    // if so: return the name
                                        // once this is received, update the redux of score.chosen_by
                                    // if not: return error

                                    let refCopy = React.createRef();

                                    return (
                                        <Reward ref={(ref) => { refCopy = ref }} key={chosen_by_index} type='confetti'>
                                            <div className={ "result-chosen" + (( this.props.user.revealID !== null && this.props.user.revealID === person.id ) ? ' revealing-name' : '' ) }
                                                id={person.id}
                                                key={person.id} 
                                                onClick={() => {
                                                    firebase.auth().currentUser.getIdToken().then((token) => {
                                                        this.props.revealName(this.props.auth.uid, person.id, score.sid, token, refCopy);
                                                    });
                                                }
                                                }>
                                                <span className="chosen-by">
                                                    <span className={"name " + (person.locked ? "locked" : "unlocked")}>{ person.locked ? 'anonymous person' : person.name }</span> 
                                                    <span className="number"> { person.class_year } { person.gender !== 'unknown' ? person.gender : '' } </span>
                                                </span>
                                            </div>
                                        </Reward>
                                    );
                                }) }
                            </h5>
                        </div>
                    </div>
                </div>
            );
        });

        if (sorted_scores.length === 0) {
            return (
                <p style={{ fontWeight: 'bold', opacity: 0.3, color: 'white', textAlign: 'center' }}>nothing to see yet, add more friends to be seen in their polls!</p>
            );
        }
        
        return (
            <div className="score-board">
                { score_board }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    user: state.user,
});

const mapDispatchToProps = dispatch => {
    return {
        revealName: (uid, pid, sid, token, rewardManager) => dispatch(revealName(uid, pid, sid, token, rewardManager)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PollResults);
