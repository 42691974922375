import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Form, FormGroup, Label, Navbar, NavbarBrand, NavbarText, Button } from 'reactstrap';
import { FiChevronLeft } from 'react-icons/fi';
import { MdVerified } from 'react-icons/md';
import { LoaderDots } from '@thumbtack/thumbprint-react';

class Unlock extends Component {
    render() {
        const verifiedState = this.props.user.verified ? <MdVerified style={{ marginLeft: 10 }}/> : <LoaderDots theme="muted" size="small" />;
        
        return (
            <Container className="signup-container">
                <Form style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
                    <FiChevronLeft className="back-arrow" onClick={() => this.props.prevStep()} />
                    <FormGroup>
                        <Label for="exampleEmail">
                            <h2 style={{ display: 'flex', alignItems: 'center' }}>{ this.props.user.verified ? 'all verified!' : 'verifying account' } { verifiedState }</h2>
                        </Label>
                        <h5 style={{ opacity: 0.5 }}>{ !this.props.user.verified ?  "much awesomeness awaits...just a few more seconds!" : 'your account verification has been complete!' }</h5>
                    </FormGroup>
                    { this.props.accountConfirmed ? 
                        <Navbar expand="md">
                            <NavbarBrand>
                                <h6 style={{ opacity: 0.5 }}>you're verified and ready to go!</h6>
                            </NavbarBrand>
                            <NavbarText style={{ textAlign: 'right' }}>
                                <Button style={{ width: '150px', marginLeft: 'auto' }} onClick={() => this.props.finish()}>see your profile &gt;</Button>
                            </NavbarText>
                        </Navbar>
                        : <p style={{ opacity: 0.3 }}>if any problems arise, contact us at team@lovingpine.org</p>
                    }
                </Form>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        auth: state.auth,
    }
}

export default connect(mapStateToProps, null)(Unlock);