import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ImCross } from 'react-icons/im';

import firebase from 'firebase/compat/app';

import { Container } from 'reactstrap';

import { removeFriend } from '../actions/user';

class Friends extends Component {
    nameToEmoji = (name) => {
        // Create a hash of the input name
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
          hash += name.charCodeAt(i);
        }
      
        // Use the hash to determine the emoji
        let emoji;
        switch (hash % 10) {
          case 0:
            emoji = "😃";
            break;
          case 1:
            emoji = "😇";
            break;
          case 2:
            emoji = "🤪";
            break;
          case 3:
            emoji = "😎";
            break;
          case 4:
            emoji = "😍";
            break;
          case 5:
            emoji = "🤗";
            break;
          case 6:
            emoji = "😏";
            break;
          case 7:
            emoji = "🤩";
            break;
          case 8:
            emoji = "☺️";
            break;
          case 9:
            emoji = "🤠";
            break;
          default:
            emoji = "😁";
        }
      
        return emoji;
    }

    render() {
        // create a map which maps each friend to the number of affirmations you have sent them
        let friendMap = {};

        // this.props.user.given is a key value pair where key is qid and value is an array of affirmations
        for (let qid in this.props.user.given) {
            // iterate through the affirmations
            for (let i = 0; i < this.props.user.given[qid].length; i++) {
                // get the affirmation
                let affirmation = this.props.user.given[qid][i];
                // get the friend
                let friend = affirmation.recipientName;
                // if the friend is not in the map, add them
                if (!(friend in friendMap)) {
                    friendMap[friend] = 0;
                }
                // increment the number of affirmations sent to the friend
                friendMap[friend]++;
            }
        }

        // onClick={(e) => this.props.toggleModal(e, friend)}
        return (
            <Container>
                { // for each user in the friends array, render a friend component
                this.props.user.friends.length > 0 ? this.props.user.friends.map((friend, index) => (
                    <div key={index} className='friend'>
                        <div className='friend-info'>
                            <h4>{ friend.label.toLowerCase() } { this.nameToEmoji(friend.label.toLowerCase()) }</h4>
                            <p style={{ opacity: 0.55 }}>you've sent them { friend.label in friendMap ? friendMap[friend.label] : 'no' } affirmation{ friend.label in friendMap ? ( friendMap[friend.label] > 1 ? 's' : '' ) : 's' }</p>
                        </div>
                        <div className='friend-actions delete-friend'>
                            <ImCross onClick={
                                (e) => {
                                    e.preventDefault();
                                    // show a confirmation modal
                                    if (window.confirm(`Are you sure you want to remove ${friend.label} from your friends? You will not be able to see them in polls anymore.`)) {
                                        // remove the friend from the user's friends list
                                        firebase.auth().currentUser.getIdToken().then((token) => {
                                          this.props.removeFriend(token, friend);
                                        });
                                    }
                                }
                            } />
                        </div>
                    </div>
                )) : <p style={{ opacity: 0.55, marginTop: 15 }}>you have no friends yet, go add some on the polls page!</p> }
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    removeFriend: (token, friend) => dispatch(removeFriend(token, friend)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Friends);