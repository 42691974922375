import firebase from 'firebase/compat/app'
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

import 'firebase/compat/firestore'
import 'firebase/compat/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBtY6o4_OK6Kua6nb3xPB2JI9_Xdvz2ahI",
  authDomain: "affirmations-6424d.firebaseapp.com",
  projectId: "affirmations-6424d",
  storageBucket: "affirmations-6424d.appspot.com",
  messagingSenderId: "258766598191",
  appId: "1:258766598191:web:af90cf658a2a791e5f56da",
  measurementId: "G-CYX03SZCEB"
};


const app = firebase.initializeApp(firebaseConfig);
getAnalytics(app);

export const auth = getAuth();
export default firebase;