import { HIDE_AFFIRMATION_NOTIFICATIONS, REMOVE_FRIEND_FAILURE, INVITE_FAILURE, INVITE_SUCCESS, INVITE_LOADING, SUGGESTIONS_LOADING, SUGGESTIONS_FAILURE, SUGGESTIONS_SUCCESS, USER_NOT_REGISTERED, SHUFFLING_START, SHUFFLING_END, POLL_SHUFFLE, POLL_TARGET_CHOSE, POLL_AWARD, REVEAL_SUCCESS, REVEAL_FAILURE, REVEAL_LOADING, POLL_TIMEOUT, CUSTOM_CHOICES, CUSTOM_RECIPIENT, POLL_SENT, REMOVE_FRIEND, POLL_FAILURE, POLL_LOADING, POLL_SUCCESS, REGISTER_LOADING, REGISTER_SUCCESS, REGISTER_FAILURE, SETTING_FRIENDS, SETTING_FRIENDS_SUCCESS, SETTING_FRIENDS_FAILURE, PREVIEW_SUCCESS, PREVIEW_LOADING, PREVIEW_FAILURE, AUTHLINK_LOADING, AUTHLINK_SUCCESS, AUTHLINK_FAILED, INFORMATION_SUCCESS, INFORMATION_LOADING, INFORMATION_FAILURE, AFFIRMATION_SENDING, AFFIRMATION_SUCCESS, AFFIRMATION_FAILURE, UNLOCK_SUCCESS, UNLOCK_LOADING, UNLOCK_FAILURE, VERIFY_LOADING, VERIFY_SUCCESS, VERIFY_FAILURE, REMOVE_FRIEND_SUCCESS } from "../actions/user";

export default function user(state = {
    suggestions: [],
    suggestionsLoading: true,
    tokens: 0,
    last_login: null,
    isSending: false,
    isSent: false,
    username: null,
    uid: null,
    affirmations: [],
    unlockLoading: false,
    authEmailRequest: false,
    authEmailSent: false,
    preview: null,
    previewLoading: true,
    previewError: false,
    friends: [],
    customPollChoices: [null, null, null, null],
    customPollRecipient: null,
    settingFriends: false,
    informationLoading: true,
    registering: false,
    register_error: null,
    given: {},
    received: {},
    poll: null,
    pollLoading: false,
    timeout: false,
    time_to_wait: 0,
    session_polls_answered: 0,
    session_polls_skipped: 0,
    reveals_reward: 0,
    question_limit: 0,
    revealLoading: false,
    revealID: null,
    revealedName: null,
    shuffling: false,
    scores: [],
    potential_friends: [],
    tip: '',
    user_registered: null,
    verified: null,
    toBeAdded: {},
    inviteSending: false,
    total_polls: null,
 }, action) {
    switch (action.type) {
        case INVITE_FAILURE:
            return {
                ...state,
                inviteSending: false,
            }

        case INVITE_SUCCESS:
            return {
                ...state,
                inviteSending: false,
            }

        case INVITE_LOADING:
            return {
                ...state,
                inviteSending: true,
            }

        case REVEAL_LOADING:
            return {
                ...state,
                revealLoading: true,
                revealID: action.payload,
            }

        case HIDE_AFFIRMATION_NOTIFICATIONS:
            // the payload will contain a qid, set all affirmations with that qid to seen
            // convert action.payload to a string

            let affirmations = state.received[action.payload];

            affirmations.forEach((affirmation) => {
                affirmation.seen = true;
            });

            return {
                ...state,
                received: { ...state.received, [action.payload]: affirmations },
            }
        
        case REVEAL_SUCCESS:
            const unlocked_name = action.payload.unlocked_name;
            const name_id = action.payload.name_id;
            const score_id = action.payload.score_id;

            // update the chosen_by array of the score in scores with sid = score_id
            // update the name of the person in chosen_by with id = name_id
            let scores = state.scores;
            let score = scores.find((score) => score.sid === score_id);
            let chosen_by = score.chosen_by;
            let person = chosen_by.find((person) => person.id === name_id);

            person.name = unlocked_name;
            person.locked = false;

            return {
                ...state,
                revealLoading: false,
                revealID: null,
                scores: scores,
                tokens: state.tokens - 1,
            }
        
        case REVEAL_FAILURE:
            return {
                ...state,
                revealLoading: false,
                revealID: null,
            }
            
        case CUSTOM_CHOICES:
            // action.payload will contain an index and a choice
            let choices = state.customPollChoices;
            choices[action.payload.index] = action.payload.choice;

            return {
                ...state,
                customPollChoices: choices,
            }

        case CUSTOM_RECIPIENT:
            return {
                ...state,
                customPollRecipient: action.payload,
            }

        case VERIFY_LOADING:
            return {
                ...state,
                verified: null,
            }

        case VERIFY_SUCCESS:
            return {
                ...state,
                verified: true,
            }
    
        case VERIFY_FAILURE:
            return {
                ...state,
                verified: false,
            }

        case REGISTER_LOADING:
            return {
                ...state,
                registering: true,
            }
        
        case REGISTER_SUCCESS:
            return {
                ...state,
                registering: false,
                verified: true,
            }

        case REGISTER_FAILURE:
            return {
                ...state,
                registering: false,
                register_error: action.payload
            }

        case SETTING_FRIENDS:
            return {
                ...state,
                settingFriends: true,
                toBeAdded: { ...state.toBeAdded, [action.payload.value]: true }
            }            

        case SETTING_FRIENDS_SUCCESS:
            // to be added is a dictionary of friends that are being added
            // remove the friend from toBeAdded

            const updatedToBeAdded = { ...state.toBeAdded };

            if (action.payload in updatedToBeAdded) {
                delete updatedToBeAdded[action.payload.value];
            }

            return {
                ...state,
                settingFriends: false,
                friends: [...state.friends, action.payload],
                toBeAdded: updatedToBeAdded,
            }

        case SETTING_FRIENDS_FAILURE:
            const newToBeAdded = { ...state.toBeAdded };

            if (action.payload.value in newToBeAdded) {
                delete newToBeAdded[action.payload.value];
            }

            return {
                ...state,
                settingFriends: false,
                toBeAdded: newToBeAdded,
            }

        case PREVIEW_LOADING:
            return {
                ...state,
                previewLoading: true,
                previewError: false,
            };
        
        case PREVIEW_SUCCESS:
            return {
                ...state,
                previewLoading: false,
                previewError: false,
                preview: action.payload,
            };
        
        case PREVIEW_FAILURE:
            return {
                ...state,
                previewLoading: false,
                previewError: true,
                preview: action.payload,
            };

        case AUTHLINK_LOADING:
            return {
                ...state,
                authEmailRequest: true,
            };

        case AUTHLINK_SUCCESS:
            return {
                ...state,
                authEmailRequest: false,
                authEmailSent: true,
            };

        case AUTHLINK_FAILED:
            return {
                ...state,
                authEmailRequest: false,
                authEmailSent: false,
            };

        case SUGGESTIONS_LOADING:
            return {
                ...state,
                suggestionsLoading: true,
            };

        case SUGGESTIONS_SUCCESS:
            // we want to add a 'new' field to action.payload.suggestions but only if suggestions already contain values
            if (state.suggestions.length > 0) {
                action.payload.suggestions.forEach((suggestion) => {
                    suggestion.new = true;
                });
            }

            // merge state.suggestions with action.payload.suggestions and remove items which have the same value
            const mergedSuggestions = [...action.payload.suggestions, ...state.suggestions];
            const updatedSuggestions = mergedSuggestions.filter((item, index, self) => {
                return index === self.findIndex((t) => (
                    t.value === item.value
                ));
            });

            // console.log('merged: ', mergedSuggestions);

            // create a variable which holds the total number of new suggestions
            const newSuggestions = updatedSuggestions.length - state.suggestions.length;

            // console.log('new: ', newSuggestions);

            if (action.showToast && newSuggestions > 0) {
                action.toastManager.add("✨ you've got " + newSuggestions + " new suggestion" + (newSuggestions > 1 ? 's' : '') + "! ✨", { appearance: 'success', autoDismiss: true });
            }

            if (newSuggestions > 0) {
                return {
                    ...state,
                    suggestionsLoading: false,
                    suggestions: updatedSuggestions,
                };
            } else {
                return {
                    ...state,
                    suggestionsLoading: false,
                };
            }
            
        case SUGGESTIONS_FAILURE:
            return {
                ...state,
                suggestionsLoading: false,
            };

        case INFORMATION_FAILURE:
            return {
                ...state,
                informationLoading: false,
            };

        case USER_NOT_REGISTERED:
            return {
                ...state,
                user_registered: false,
                informationLoading: false,
            };

        case POLL_SHUFFLE:
            // we are going to inject new choices into the poll
            const new_choices = action.payload.choices;

            return {
                ...state,
                poll: {
                    ...state.poll,
                    choices: new_choices,
                },
                pollLoading: false,
            }

        case SHUFFLING_START:
            return {
                ...state,
                shuffling: true,
            }

        case SHUFFLING_END:
            return {
                ...state,
                shuffling: false,
            }
        
        case POLL_TARGET_CHOSE:
            return {
                ...state,
                tokens: state.tokens + 3,
            }

        case INFORMATION_SUCCESS:
            return {
                ...state,
                tip: action.payload.data.tip,
                scores: action.payload.data.scores,
                given: action.payload.given,
                received: action.payload.received,
                tokens: action.payload.data.tokens,
                uid: action.payload.data.uid,
                username: action.payload.data.displayName,
                last_login: action.payload.data.last_login,
                year: action.payload.data.year,
                friends: action.payload.data.friends || [],
                informationLoading: false,
                total_polls: action.payload.data.total_polls ? action.payload.data.total_polls : 0,
                reveals_reward: action.payload.data.reveals_reward || 0,
                admin: action.payload.data.admin || false,
                customPollChoices: [{ email: action.payload.data.email, value: action.payload.data.uid, label: action.payload.data.displayName, year: action.payload.data.year }, null, null, null],
                user_registered: true,
            };

        case INFORMATION_LOADING:
            return {
                ...state,
                informationLoading: true,
                tokens: 0,
                uid: 0
            };
        
        case AFFIRMATION_SENDING:
            return {
                ...state,
                isSending: true,
            };

        case AFFIRMATION_SUCCESS:
            return {
                ...state,
                isSending: false,
                isSent: true,
                tokens: state.tokens + 1,
            };
        
        case AFFIRMATION_FAILURE:
            return {
                ...state,
                isSent: false,
                isSending: false
            };

        case UNLOCK_SUCCESS:
            return {
                ...state,
                tokens: state.tokens - 1,
                unlockLoading: false,
                received: {
                    ...state.received,
                    [action.payload.qid]: state.received[action.payload.qid].map((a) => {
                        if (a.id === action.payload.id) {
                            return {
                                ...a,
                                locked: false,
                                from: action.payload.senderName
                            };
                        }
                        return a;
                    })
                }
            };
        
        case UNLOCK_LOADING:
            return {
                ...state,
                unlockLoading: true
            };

        case UNLOCK_FAILURE:
            return {
                ...state,
                unlockLoading: false
            };

        case POLL_LOADING:
            return {
                ...state,
                pollLoading: true
            };
        
        case POLL_FAILURE:
            return {
                ...state,
                pollLoading: false
            };

        case POLL_SENT:
            return {
                ...state,
                pollLoading: false,
                customPollChoices: [null, null, null, null],
                customPollRecipient: null,
            }

        case POLL_SUCCESS:
            return {
                ...state,
                timeout: false,
                pollLoading: false,
                poll: action.payload.poll,
                customPollChoices: [null, null, null, null],
                customPollRecipient: null,
                session_polls_answered: action.payload.session_polls_answered,
                session_polls_skipped: action.payload.session_polls_skipped,
                question_limit: action.payload.question_limit,
            };

        case POLL_TIMEOUT:
            return {
                ...state,
                timeout: true,
                time_to_wait: action.payload,
                session_polls_answered: state.session_polls_answered + 1,
                pollLoading: false,
            };

        case POLL_AWARD:
            return {
                ...state,
                tokens: state.tokens + action.payload,
                reveals_reward: action.payload,
            };

        case REMOVE_FRIEND:
            // remove the friend from the list
            return {
                ...state,
                friends: state.friends.filter((f) => f.value !== action.payload.value),
            };

        case REMOVE_FRIEND_SUCCESS:
            return {
                ...state,
                settingFriends: false,
            };

        case REMOVE_FRIEND_FAILURE:
            return {
                ...state,
                settingFriends: false,
            };

        default:
            return state;
    }
 }