import React, { Component } from "react";

export default class Terms extends Component {
    render() {
        return `
        Terms of Service
Last Updated January 12, 2023

Thank you for using Loving Pine
Welcome to Loving Pine. Loving Pine is an app to send affirmations to students in college.

These Terms of Service (“Terms”) govern access and use of our app, website, and other tools (collectively, “Loving Pine” or the “Service” or “Services”). Each time you access or use Loving Pine, you agree to be bound by these Terms and any additional terms that apply to you. If you do not agree to be bound by these Terms, please stop using Loving Pine. Loving Pine refers to (“Company,” “we” or “us”). For purposes of these Terms, “you” and “your” means you as the user of the Services.

We also have a Privacy Policy that discusses what information we collect and how we use this information. You should read the Privacy Policy because it governs the Companys use of your personal information. 

IMPORTANT NOTE: THE SECTION TITLED “ARBITRATION AND GOVERNING LAW” CONTAINS AN ARBITRATION CLAUSE AND CLASS-ACTION WAIVER THAT APPLIES TO ALL U.S.-BASED Loving Pine USERS. PLEASE READ THIS SECTION CAREFULLY AS IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT. YOU HAVE THE RIGHT TO OPT-OUT OF THE ARBITRATION CLAUSE AND THE CLASS ACTION WAIVER AS EXPLAINED IN SECTION THE SECTION TITLED “ARBITRATION AND GOVERNING LAW.”

Age and legal capacity
You represent that you are at least 13 years of age and possess the legal capacity to enter into the agreement set forth in these Terms. No one under the age of 13 is allowed to use or access Loving Pine. If you are old enough to use or access Loving Pine but are not old enough to have authority to consent to our terms, your parent or guardian must agree to these Terms on your behalf. If youre a parent or legal guardian, and you allow your teenager to use the services, then these Terms also apply to you and youre responsible for your college students activity on the services.

If you are a resident of the European Economic Area (“EEA”), you may only use Loving Pine if you are over the age at which you can provide consent to data processing under the laws of your country or if verifiable parental consent for your use of Loving Pine has been provided to us.

Accounts and Software Updates
When you create your Loving Pine account, you agree that the information you submit is correct and complete. We may refuse you access to the Service if we learn that the information you provided is inaccurate. You may stop using the Services at any time and for any reason. You can delete your Loving Pine account by tapping Edit Profile › Manage Account › Delete Account.

Your license to use Loving Pine
The Services, including their “look and feel” (e.g., text, graphics, images, logos), proprietary content, information and other materials, and the proprietary software and other technology used to provide the Services are protected under copyright, trademark and other intellectual property laws. You agree that the Company and/or its licensors own all right, title and interest in and to the Services (including any and all intellectual property rights therein) and you agree not to take any action(s) inconsistent with such ownership interests. Although we are granting you this license, we and our licensors retain all intellectual property rights we have in our software and Services (other than User Content (defined below)), including, without limitation, the exclusive right to create derivative works.

The Loving Pine name and the Loving Pine logo or icon (tree design) and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. The unauthorized use of any of these trademarks is strictly prohibited.

You may not copy, modify, create derivative works based upon, distribute, sell, lease, or sublicense any of our software or Services. You also may not reverse engineer or decompile our software or Services, attempt to do so, or assist anyone in doing so, unless you have our written consent or applicable law permits it.

You own what you post
We refer to the poll answers, affirmations, and other content you post as ”User Content.” You retain all rights in, and are solely responsible for, the User Content you post to Loving Pine.

While your User Content belongs to you, several important limitations apply:

First, User Content does not include your Loving Pine username. Should you or Loving Pine terminate your account, you do not retain any rights in the username you used. A new Loving Pine user may use the user name in the future.

Second, you grant Loving Pine and our users a non-exclusive, royalty-free, transferable, sublicensable, worldwide license to use, store, display, reproduce, save, modify, create derivative works, perform, and distribute your User Content for the purposes of using, accessing, operating, developing and providing Loving Pine. Nothing in these Terms restricts other legal rights Loving Pine may have to User Content, for example under other licenses.

Third, we retain the right to remove or modify User Content, or change the way it’s used in Loving Pine, for any reason. This includes User Content that in our judgment violates these Terms or any other policies.

Fourth, content you share with others on Loving Pine may continue to exist on Loving Pine even if you leave Loving Pine. We may backup, archive and retain your User Content even if you terminate or deactivate your account, or delete or remove specific User Content. Loving Pine and its users may retain and continue to use, store, display, reproduce, re-pin, modify, create derivative works, perform, and distribute any of your User Content that other users have stored or shared on Loving Pine.

If you post or visit a link posted on Loving Pine
We may allow you to post links to third party websites, information, materials, products, or services. Please do not post links in violation of these Terms. Loving Pine does not control nor endorse or assume any responsibility for these links. If you access any third party website, service, or content from Loving Pine, you do so at your own risk. You accept and agree that Loving Pine has no liability arising from your use of or access to any third party website, service, or content.

If you provide us feedback
We appreciate user input on ways to make Loving Pine better. If you make any comments or suggestions to Loving Pine, you grant us a non-exclusive, perpetual, irrevocable, transferable, sublicensable license to use the feedback and ideas, content and technology generated from the feedback without any restrictions, attribution, or compensation to you.

Loving Pine doesn’t waive any rights to use similar or related feedback provided to Loving Pine before or after your feedback, or developed by Loving Pine’s employees, or obtained from sources other than you.

Keeping your account secure
While we cannot guarantee your account from hacking by an unauthorized party, we employ security measures to keep your account and content secure.
Conditions of Access and Use
User Conduct
You are solely responsible for all code, video, images, information, data, text, software, music, sound, photographs, graphics, messages, and other materials (“content”) that you make available to Company, including by uploading, posting, publishing, or displaying (hereinafter, “upload(ing)”) via the Service or by emailing or otherwise making available to other users of the Service). The following are examples of the kinds of content and/or uses that are illegal or prohibited by Company. Company reserves the right to investigate and take appropriate legal action against anyone who, in Company’s sole discretion, violates this provision, including removing the offending content from the Service, suspending or terminating the account of such violators, and reporting the violator to law enforcement authorities. You agree to not use the Service to:

email or otherwise upload any content that (i) infringes any intellectual property or other proprietary rights of any party; (ii) you do not have a right to upload under any law or under contractual or fiduciary relationships; (iii) contains software viruses or any other computer code, files or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment; (iv) poses or creates a privacy or security risk to any person; (v) constitutes unsolicited or unauthorized advertising, promotional materials, commercial activities and/or sales, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” “contests,” “sweepstakes,” or any other form of solicitation; (vi) is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s privacy, hateful, discriminatory, or otherwise objectionable; or (vii) in the sole judgment of Company, is objectionable or which restricts or inhibits any other person from using or enjoying the Service, or which may expose Company or its users to any harm or liability of any type;
interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any requirements, procedures, policies, or regulations of networks connected to the Service;
violate any applicable local, state, national, or international law, or any regulations having the force of law; 
impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;
solicit personal information from anyone under the age of 18;
harvest or collect email addresses or other contact information of other users from the Service by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;
advertise or offer to sell or buy any goods or services for any business purpose that is not specifically authorized;
further or promote any criminal activity or enterprise or provide instructional information about illegal activities
obtain or attempt to access or otherwise obtain any content or information through any means not intentionally made available or provided for through the Service;
circumvent, remove, alter, deactivate, degrade, or thwart any of the content protections in or geographic restrictions on any content (including Service Content (as defined below)) available on or through the Service, including through the use of virtual private networks; or
engage in or use any data mining, robots, scraping, or similar data gathering or extraction methods. If you are blocked by Company from accessing the Service (including by blocking your IP address), you agree not to implement any measures to circumvent such blocking (e.g., by masking your IP address or using a proxy IP address or virtual private network).
Service Content
You acknowledge and agree that the Service may contain content or features (“Service Content”) that are protected by copyright, patent, trademark, trade secret, or other proprietary rights and laws. Except as expressly authorized by Company, you agree not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute, or create derivative works based on the Service or the Service Content, in whole or in part, except that the foregoing does not apply to your own User Content that you upload to or make available through the Service in accordance with these Terms of Service. Any use of the Service or the Service Content other than as specifically authorized herein is strictly prohibited.

Competitors
No employee, independent contractor, agent, or affiliate of any competing social media company is permitted to view, access, or use any portion of the Service without express written permission from Company. By viewing, using, or accessing the Service, you represent and warrant that you are not a competitor of Company or any of its affiliates, or acting on behalf of a competitor of Company in using or accessing the Service.


Commercial Use
Unless otherwise expressly authorized herein or in the Service, you agree not to display, distribute, license, perform, publish, reproduce, duplicate, copy, create derivative works from, modify, sell, resell, exploit, transfer, or upload for any commercial purposes, any portion of the Service, use of the Service, or access to the Service. The Service is for your personal use.

Help
To obtain help you may: contact team@lovingpine.org


Concerning Copyrights
Our Service is protected by copyrights, trademarks and other proprietary rights, and may not be used for any purpose or in any manner that infringes the rights of any third party.

We encourage you to report any content on Loving Pine that you believe infringes your rights. Only the intellectual property rights owner or person authorized to act on behalf of the owner can report potentially infringing content. If you have a good faith belief that content on Loving Pine infringes your copyright, trademark, or other intellectual property rights, please read below for how to submit your complaint.

In accordance with the Digital Millennium Copyright Act of 1998 (the 'DMCA'), Loving Pine has a designated agent for receiving notices of copyright infringement and Loving Pine follows the notice and take-down procedures of the DMCA.

If you believe that your work has been copied in a way that constitutes copyright infringement, please provide Loving Pine's copyright agent the following information required by the DMCA, 17 U.S.C. § 512:

a physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;
identification of the material that is claimed to be infringing and information reasonably sufficient to permit us to locate the material (e.g., the username of the posting user, the URL of the post, and the date it was posted);
information reasonably sufficient to permit us to contact the complaining party;
a statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and
a statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
If you believe that any content on Loving Pine violates your rights other than copyrights, please provide Loving Pine with at least the following information: (a) a physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; (b) identification of the material that is claimed to be infringing and information reasonably sufficient to permit us to locate the material (e.g., the username of the posting user, the URL of the post, and the date it was posted); (c) an explanation of what rights you own/have and why you believe the content infringes your rights sufficient for us to evaluate your complaint; and (d) accurate contact information for you.

Please send your notice of claims of copyright infringement on or regarding Loving Pine or other complaint regarding alleged violation of rights to Loving Pine’s copyright agent, who can be reached as follows:

It is often difficult to determine if your intellectual property rights have been violated or if the DMCA requirements have been met. We may request additional information before we remove any purportedly infringing material. We have a policy of terminating the accounts of users who (in our reasonable discretion) are repeat infringers.

Termination
We may suspend or terminate your license to access and use Loving Pine at any time and for any reason. Normally we provide notice and the reason for the termination or suspension. However, our termination or suspension may be immediate and without notice if in our sole judgement you committed a serious violation of these Terms or our policies.

Indemnity
You agree to indemnify, defend and hold harmless Loving Pine, and its directors, officers, employees and agents, from any and all legal actions, demands, claims, losses, expenses or damages arising out of or in any way related to your access or use of Loving Pine or our Service (or any other person accessing or using Loving Pine through your account), your User Content, any breach of these Terms or violation of our policies.

In such circumstances, you agree we are entitled to payment from you for any judgment, our reasonable legal fees and all costs we incur to defend ourselves. Our right to indemnification survives any cancellation, termination or suspension of your Loving Pine account.

Disclaimer and waiver of claims
WE MAKE NO REPRESENTATIONS WHATSOEVER AS TO THE ACCURACY, USEFULNESS, SAFETY, OR INTELLECTUAL PROPERTY RIGHTS OF ANY INFORMATION POSTED ON Loving Pine OR THE Loving Pine SOFTWARE. OUR SERVICE, SOFTWARE AND ALL CONTENT ON Loving Pine IS PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.

YOUR USE OF Loving Pine IS AT YOUR OWN RISK. WE SPECIFICALLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.

We do not review User Content in advance of its posting. Loving Pine takes no responsibility and assumes no liability for any User Content that you or any other person or third party posts or sends using our Service. You understand and agree that you may be exposed to User Content that’s inaccurate, offensive, indecent, objectionable, inappropriate for children, or otherwise unsuited to your purpose.

This disclaimer and waiver of claims survives any cancellation, termination or suspension of your Loving Pine account and termination of these Terms.

Our liability is limited
TO THE MAXIMUM EXTENT PERMITTED BY LAW, Loving Pine, AND ITS OWNERS, OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS SHALL NOT BE LIABLE UNDER ANY THEORY OF LAW OR IN EQUITY FOR ANY INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES, OR FOR ANY BUSINESS INTERRUPTION OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM THE USE OF INABILITY TO USE OUR SERVICE OR ITS CONTENT; ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICE, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONTENT OR CONDUCT OF OTHER USERS OR THIRD PARTIES; UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT; ANY CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS, OR OTHER INACCURACIES IN THE SERVICE OR THE CONTENT); HOWSOEVER CAUSED, EVEN IF Loving Pine HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ANY LIABILITY FOR CLAIMS BROUGHT AGAINST THE COMPANY OR ANY OF ITS AFFILIATES OR ANY OF THEIR OWNERS, OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS IS LIMITED TO $100.00 OR SUCH THE SMALLEST AMOUNT PERMITTED BY LAW, WHICHEVER IS LESSER.

IF YOU ARE DISSATISFIED WITH THE SERVICE, THE CONTENT OR WITH THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICE.

If we cause damage to you and you're a consumer in the EEA, the above doesn’t apply. Instead, Loving Pine’s liability will be limited to foreseeable damages arising due to a breach of material contractual obligations typical for this type of contract. Loving Pine isn’t liable for damages that result from a non-material breach of any other applicable duty of care. This limitation of liability won’t apply to any statutory liability that cannot be limited, to liability for death or personal injury caused by our negligence or willful misconduct, or if and to exclude our responsibility for something we have specifically promised to you in writing.

This limitation of liability provision survives any cancellation, termination or suspension of your Loving Pine account and termination of these Terms.

Arbitration and governing law
If a dispute arises with Loving Pine, please contact us. We will work to address it with you quickly and informally.

Should an informal resolution of the dispute prove impossible, you agree that any dispute, claim or controversy arising out of or relating to your access or use of Loving Pine or these Terms, including the determination of the scope or applicability of this provision, shall be determined by final, binding and non-appealable arbitration.

Arbitration is a less expensive, and often quicker, means for parties to resolve their dispute than filing a lawsuit in a court of law. An independent and neutral arbitrator decides the case, not a judge or jury. The arbitration award is final and not subject to review by a court. Arbitrators, however, can award the same damages and relief that a court can award.

You agree that, by agreeing to these Terms of Service, the U.S. Federal Arbitration Act governs the interpretation and enforcement of this provision and Agreement, and that you and Loving Pine are each waiving the right to a trial by jury or to participate in a class action. The arbitrator has exclusive authority to resolve any dispute relating to the interpretation, applicability, or enforceability of this binding arbitration agreement. This arbitration provision shall survive termination of these Terms and the termination of your Loving Pine account. Any arbitration will be administered by the American Arbitration Association ("AAA") under the Consumer Arbitration Rules then in effect for the AAA, except as provided herein. You can find their forms at www.adr.org.

Unless you and Loving Pine agree otherwise, the arbitration will be conducted in the City and County of San Francisco or Alameda County, California. Each party will be responsible for paying any AAA filing, administrative and arbitrator fees in accordance with AAA Rules, except that Loving Pine will pay for your reasonable filing, administrative, and arbitrator fees if your claim for damages does not exceed $75,000 and is non-frivolous (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)).

If your claim is for $10,000 or less, we agree that you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic hearing, or by an in-person hearing as established by the AAA Rules. If your claim exceeds $10,000, the right to a hearing will be determined by the AAA Rules.

Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision explaining the essential findings and conclusions on which the award is based, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Nothing in this Section shall prevent either party from seeking injunctive or other equitable relief from the courts, including for matters related to data security, intellectual property or unauthorized access to the Service.

ALL CLAIMS MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND Loving Pine ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.

NOTHING IN THESE TERMS OF SERVICE SHALL AFFECT ANY NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO YOU.

To the extent any claim, dispute or controversy regarding Loving Pine or our Service isn’t arbitrable under applicable laws or otherwise: you and Loving Pine both agree that any claim or dispute regarding Loving Pine will be resolved exclusively in a New Hampshire court governed by the laws of the State of New Hampshire, without respect to its conflict of laws principles.

If you're a consumer in the EEA, this arbitration provision does not apply to you.

Opt-out 
You can decline this agreement to arbitrate by emailing an opt-out notice to team@lovingpine.org within 30 days of January 12, 2023, or within 30 days of when you first register your Loving Pine account, whichever is later, otherwise you shall be bound to arbitrate disputes in accordance with the terms of these paragraphs. If you opt out of these arbitration provisions, Loving Pine also will not be bound by them.

We may modify these Terms
We are continually working to improve Loving Pine. As a result, our Terms may be modified or we may publish new Terms at any time. Any modified and/or new Terms will become effective immediately upon posting. You agree that we may provide you notice of new or modified terms by posting them on the Loving Pine website and/or within the settings of the Loving Pine app. By continuing to access and use Loving Pine, you agree to be bound by the new or modified terms.

General provisions
Assignment
These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Loving Pine without restriction. Any attempted transfer or assignment in violation hereof shall be null and void.

If you’re a consumer in the EEA, either you or Loving Pine may assign these Terms, and any rights and licenses granted under it, to a third party. In case of such an assignment by Loving Pine, you are entitled to terminate the agreement with immediate effect by deactivating your account. Loving Pine will provide you with reasonable notice of any such assignment.

Severability
If any provision of these Terms shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms and remaining provisions shall remain in full force and valid. 

No waiver
No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and Loving Pine's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.

Open Source
Some of Loving Pine's services include software subject to separate open source license terms, and your use of those services are subject to your compliance with those license terms, when applicable. We encourage you to review them, as some licenses may explicitly override these Terms.`
    }
}