import { FEED_FAILURE, FEED_LOADING, FEED_SUCCESS } from '../actions/user';

export default function feed(state = {
    loaded: false,
    error: null,
    feed: [],
    global: [],
    total_likes_about_you: [{ total: 0 }],
    total_likes_on_your_choices: [{ total: 0 }],
    lastUpdated: 0,
}, action) {
    switch (action.type) {
        case FEED_LOADING:
            return {
                ...state,
                loaded: state.feed.length !== 0,
            };

        case FEED_SUCCESS:
            if (action.payload.feed.length > 0 && state.feed.length > 0) {
                const toastManager = action.payload.toastManager;

                // remove notifications which correspond to the user id
                
                toastManager.add(`new updates from ${action.payload.feed.length} friend${ action.payload.feed.length === 1 ? '' : 's' }!`, {
                    appearance: 'success',
                });
            }

            // toast the user if they have new likes
            if (action.payload.total_likes_on_your_choices[0].total > state.total_likes_on_your_choices[0].total && state.total_likes_on_your_choices[0].total !== 0) {
                const toastManager = action.payload.toastManager;

                toastManager.add(`someone liked one of your compliments! ✨`, {
                    appearance: 'success',
                });
            }

            if (action.payload.total_likes_about_you[0].total > state.total_likes_about_you[0].total && state.total_likes_about_you[0].total !== 0) {
                const toastManager = action.payload.toastManager;

                toastManager.add(`someone liked a compliment about you! ❤️`, {
                    appearance: 'success',
                });
            }

            // let lastUpdated be the largest timestamp in the feed
            let lastUpdated = state.lastUpdated;
            let allUpdates = [...action.payload.feed, ...action.payload.global];
            allUpdates.forEach((item) => {
                if (item.timestamp > lastUpdated) {
                    lastUpdated = item.timestamp;
                }
            });

            let newFeed = [...action.payload.feed, ...state.feed]
            let newGlobal = [...action.payload.global, ...state.global]

            // sort the feed by timestamp descending
            newFeed.sort((a, b) => {
                return b.timestamp - a.timestamp;
            });

            // sort the global feed by timestamp descending
            newGlobal.sort((a, b) => {
                return b.timestamp - a.timestamp;
            });

            return {
                ...state,
                loaded: true,
                feed: newFeed,
                global: newGlobal,
                total_likes_about_you: action.payload.total_likes_about_you,
                total_likes_on_your_choices: action.payload.total_likes_on_your_choices,
                lastUpdated: lastUpdated,
            };
        case FEED_FAILURE:
            return {
                ...state,
                loaded: true,
                error: action.error,
            };
        default:
            return state;
    }
}