import firebase from 'firebase/compat/app';
import {toast} from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import

import axios from "axios"

/*

# Final Push:
# -- Fix removal friend bug (1-2 hours) - Today DONE
# -- Test registration and node creation (2-3 hours)
# -- Incorporate new graph into Neo4j [priority] (3 hours) - Today
# -- Default Darkmode (30 mins) - Today DONE
# -- Button for Affirmations (10 mins) - Today
# -- Create 'Today's Compliments' email (2 hours) - Today
# -- Hashing for Webpack (1 hour)
# -- Need to move dispatch from onLoad to when user starts polls (1 hour) - today
# -- Check matchings for launch (2 hours)
# -- Sunday night: Launch!

*/

// when user adds friends from suggestions
// maybe add tab bar for suggestions / results
// toast for when we add new suggestions
// add three dots

// empty state for feed / user has no friends, just put them on everyone tab
// better tutorial etc
// add friends straight from feed, show live updates etc, show star for friends 

const DEV = false;
const URL = DEV ? 'http://localhost:8081' : 'https://for-rat-production.up.railway.app'; 

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const INFORMATION_LOADING = 'INFORMATION_LOADING';
export const INFORMATION_SUCCESS = 'INFORMATION_SUCCESS';
export const INFORMATION_FAILURE = 'INFORMATION_FAILURE';

export const SUGGESTIONS_LOADING = 'SUGGESTIONS_LOADING';
export const SUGGESTIONS_SUCCESS = 'SUGGESTIONS_SUCCESS';
export const SUGGESTIONS_FAILURE = 'SUGGESTIONS_FAILURE';

export const USER_NOT_REGISTERED = 'USER_NOT_REGISTERED';

export const AFFIRMATION_SENDING = 'AFFIRMATION_SENDING';
export const AFFIRMATION_SUCCESS = 'AFFIRMATION_SUCCESS';
export const AFFIRMATION_FAILURE = 'AFFIRMATION_FAILURE';

export const AFFIRMATIONS_LOADING = 'AFFIRMATIONS_LOADING';
export const AFFIRMATIONS_SUCCESS = 'AFFIRMATIONS_SUCCESS';
export const AFFIRMATIONS_FAILURE = 'AFFIRMATIONS_FAILURE';

export const UNLOCK_LOADING = 'UNLOCK_LOADING';
export const UNLOCK_SUCCESS = 'UNLOCK_SUCCESS';
export const UNLOCK_FAILURE = 'UNLOCK_FAILURE';

export const AUTHLINK_LOADING = 'AUTHLINK_LOADING';
export const AUTHLINK_SUCCESS = 'AUTHLINK_SUCCESS';
export const AUTHLINK_FAILED = 'AUTHLINK_FAILED';

export const PREVIEW_LOADING = 'PREVIEW_LOADING';
export const PREVIEW_SUCCESS = 'PREVIEW_SUCCESS';
export const PREVIEW_FAILURE = 'PREVIEW_FAILURE';

export const REGISTER_LOADING = 'REGISTER_LOADING';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const VERIFY_LOADING = 'VERIFY_LOADING';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'VERIFY_FAILURE';

export const SETTING_FRIENDS = 'SETTING_FRIENDS';
export const SETTING_FRIENDS_SUCCESS = 'SETTING_FRIENDS_SUCCESS';
export const SETTING_FRIENDS_FAILURE = 'SETTING_FRIENDS_FAILURE';

export const REMOVE_FRIEND = 'REMOVE_FRIEND';
export const REMOVE_FRIEND_SUCCESS = 'REMOVE_FRIEND_SUCCESS';
export const REMOVE_FRIEND_FAILURE = 'REMOVE_FRIEND_FAILURE'

export const POLL_FAILURE = 'POLL_FAILURE';
export const POLL_SUCCESS = 'POLL_SUCCESS';
export const POLL_LOADING = 'POLL_LOADING';
export const POLL_SENT = 'POLL_SENT';
export const POLL_TIMEOUT = 'POLL_TIMEOUT';
export const POLL_TARGET_CHOSE = 'POLL_TARGET_CHOSE';

export const POLL_VOTE_FAILURE = 'POLL_VOTE_FAILURE';
export const POLL_VOTE_SUCCESS = 'POLL_VOTE_SUCCESS';
export const POLL_VOTE_LOADING = 'POLL_VOTE_LOADING';
export const POLL_SHUFFLE = 'POLL_SHUFFLE';

export const CUSTOM_CHOICES = 'CUSTOM_CHOICES';
export const CUSTOM_RECIPIENT = 'CUSTOM_RECIPIENT';

export const REVEAL_LOADING = 'REVEAL_LOADING';
export const REVEAL_SUCCESS = 'REVEAL_SUCCESS';
export const REVEAL_FAILURE = 'REVEAL_FAILURE';

export const SHUFFLING_START = 'SHUFFLING_START';
export const SHUFFLING_END = 'SHUFFLING_END';

export const POLL_AWARD = 'POLL_AWARD';

export const INVITE_LOADING = 'INVITE_LOADING';
export const INVITE_SUCCESS = 'INVITE_SUCCESS';
export const INVITE_FAILURE = 'INVITE_FAILURE';

export const FEED_LOADING = 'FEED_LOADING';
export const FEED_SUCCESS = 'FEED_SUCCESS';
export const FEED_FAILURE = 'FEED_FAILURE';

export const LIKE_VOTE_LOADING = 'LIKE_VOTE_LOADING';
export const LIKE_VOTE_SUCCESS = 'LIKE_VOTE_SUCCESS';
export const LIKE_VOTE_FAILURE = 'LIKE_VOTE_FAILURE';

export const HIDE_AFFIRMATION_NOTIFICATIONS = 'HIDE_AFFIRMATION_NOTIFICATIONS';

export function requestAuthLink() {
    return {
        type: AUTHLINK_LOADING,
    }
}

export function authLinkSuccess() {
    return {
        type: AUTHLINK_SUCCESS,
    }
}

export function authLinkFailed() {
    return {
        type: AUTHLINK_FAILED,
    }
}

export function receiveLogin() {
    return {
        type: LOGIN_REQUEST,
    };
}

export function loginSuccess() {
    return {
        type: LOGIN_SUCCESS,
    };
}

function loginError(payload) {
    return {
        type: LOGIN_FAILURE,
        payload,
    };
}

function requestLogout() {
    return {
        type: LOGOUT_REQUEST,
    };
}

export function receiveLogout() {
    return {
        type: LOGOUT_SUCCESS,
    };
}

export function requestPreview() {
    return {
        type: PREVIEW_LOADING,
    }
}

export function receivePreview(affirmation) {
    return {
        type: PREVIEW_SUCCESS,
        payload: affirmation
    }
}

export function receivePreviewError() {
    return {
        type: PREVIEW_FAILURE,
        payload: {
            message: "Error loading preview",
            year: "",
            qid: 1,
        }
    }
}

export function likeVote(token, relationship_id) {
    return (dispatch) => {
        dispatch({ type: LIKE_VOTE_LOADING })
        axios.post(`${URL}/like`, { relationship_id }, {headers: { authorization: `Bearer ${token}` }})
            .then((res) => {
                dispatch({ type: LIKE_VOTE_SUCCESS, payload: res.data })
            }).catch((err) => {
                dispatch({ type: LIKE_VOTE_FAILURE })
            }
        );
    }
}

export function getFeed(token, uid, lastUpdated, toastManager) {
    return (dispatch) => {
        dispatch({ type: FEED_LOADING })

        axios.post(`${URL}/feed`, { uid, lastUpdated }, {headers: { authorization: `Bearer ${token}` }})
            .then((res) => {
                dispatch({ type: FEED_SUCCESS, payload: { feed: res.data.feed, global: res.data.global, total_likes_about_you: res.data.total_likes_about_you,
                total_likes_on_your_choices: res.data.total_likes_on_your_choices, toastManager } })
            }).catch((err) => {
                dispatch({ type: FEED_FAILURE })
            }
        );
    } 
}

export function addFriend(token, friend, toastManager) {
    return (dispatch) => {
        // console.log('prev: ', friends);
        dispatch({ type: SETTING_FRIENDS, payload: friend })
        axios.post(`${URL}/friends`, { friend, type: 'add' }, {headers: { authorization: `Bearer ${token}` }})
            .then((res) => {
                // console.log('res: ', res.data.friends);
                dispatch({
                    type: SETTING_FRIENDS_SUCCESS,
                    payload: friend
                })

                firebase.auth().currentUser.getIdToken().then((token) => {
                    dispatch(getSuggestions(token, true, toastManager))
                });

            })
            .catch((err) => {
                dispatch({
                    type: SETTING_FRIENDS_FAILURE,
                    payload: friend
                })
            })
    }
}

export function removeFriend(token, friend) {
    return (dispatch) => {
        dispatch({ type: REMOVE_FRIEND, payload: friend })
        axios.post(`${URL}/friends`, { friend, type: 'delete' }, {headers: { authorization: `Bearer ${token}` }})
            .then((res) => {
                dispatch({ type: REMOVE_FRIEND_SUCCESS });
            })
            .catch((err) => {
                dispatch({ type: REMOVE_FRIEND_FAILURE });
            })
    }
}

// who are you? async box -> welcome, John: sign in with google
// email you have authed with == email you have signed up with
// what is your name? -> attach and verify

export function verifyUser(token, code) {
    return (dispatch) => {
        dispatch({ type: VERIFY_LOADING });
        axios.post(`${URL}/unlock`, { code }, {headers: { authorization: `Bearer ${token}` }})
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: VERIFY_SUCCESS });
                // redirect to /home after 1 second
                setTimeout(() => {
                    window.location.href = '/home';
                }, 1000);
            } else {
                confirmAlert({
                    title: 'hmm...',
                    message: "that didn't work, try refreshing the page to try again!",
                    buttons: [
                      {
                        label: 'okay',
                      }
                    ]
                  });
                dispatch({ type: VERIFY_FAILURE, payload: response.data.error });
            }
        })
        .catch((error) => {
            console.log('Error: ', error);
            dispatch({ type: VERIFY_FAILURE, payload: 'failed to register, try again later :(' });
        });
    }
}

export function registerUser(token, possibleIdentityUid) {
    return (dispatch) => {
        dispatch({ type: REGISTER_LOADING });
        axios.post(`${URL}/register`, { possibleIdentityUid }, {headers: { authorization: `Bearer ${token}` }})
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: REGISTER_SUCCESS });
                setTimeout(() => {
                    window.location.href = '/home';
                }, 1000);
            } else {
                confirmAlert({
                    title: 'one last check',
                    message: "we've sent you an email to complete verification! (check your spam folder if you don't see it in your inbox within a few minutes)",
                    buttons: [
                      {
                        label: 'sounds good!',
                      }
                    ]
                  });
                dispatch({ type: REGISTER_FAILURE, payload: response.data.error });
            }
        })
        .catch((error) => {
            console.log('Error: ', error);
            dispatch({ type: REGISTER_FAILURE, payload: 'failed to register, try again later :(' });
        });
    }
}

export function fetchPreview(id) {
    return (dispatch) => {
        dispatch(requestPreview());
        axios.post(`${URL}/affirmation/preview`, { id })
        .then((response) => {
            if (response.data.success) {
                dispatch(receivePreview(response.data.affirmation));
            } else {
                dispatch(receivePreviewError());
            }
        })
        .catch((error) => {
            dispatch(receivePreviewError());
        });
    }
}

export function viewAffirmations(token, qid) {
    return (dispatch) => {
        dispatch({ type: HIDE_AFFIRMATION_NOTIFICATIONS, payload: qid })
        axios.post(`${URL}/affirmation/view`, { qid }, {headers: { authorization: `Bearer ${token}` }})
    }
}

// Logs the user out
export function logoutUser() {
    return (dispatch) => {
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
            dispatch(requestLogout());
            localStorage.removeItem('authenticated');
            dispatch(receiveLogout());
          }).catch(function(error) {
            // An error happened.
            console.log(error);
        });
    };
}

// send api req to signin endpoint
// - this generates a firebase sign in link
// - we send this to the user with courier
// - if successful, we present them with activation screen
// - they click link and return here
// use firebase to handle the api key and store the previous email in the local storage

// password-less login with firebase
export function sendLoginEmail(user, toastManager) {
    return (dispatch) => {
        const email = user.email;
        const uid = user.value;
        const year = user.year;
        const displayName = user.label;

        dispatch(requestAuthLink());

        axios.post(URL + '/signin', { email, uid, year, displayName }).then((res) => {
            if (res.data.success) {
                dispatch(authLinkSuccess());
                // save the email in local storage
                // localStorage.setItem('emailForSignIn', email);
                toastManager.add('verification email has been sent!', { appearance: 'success', autoDismiss: true });
            } else {
                dispatch(authLinkFailed());
                toastManager.add('error sending verification email :(', { appearance: 'error', autoDismiss: true });
            }
          }).catch((e) => {
            console.log(e);
            toastManager.add('error sending verification email :(', { appearance: 'error', autoDismiss: true });
            dispatch(authLinkFailed());
        });
    };
}

export function loginUser(creds) {
    return (dispatch) => {
        dispatch(receiveLogin());

        if (creds.email.length > 0 && creds.password.length > 0) {
            firebase.auth().signInWithEmailAndPassword(creds.email, creds.password)
            .then((user) => {
              localStorage.setItem('authenticated', true);
              dispatch(loginSuccess());
              creds.history.push('/home');
            })
            .catch((error) => {
              var errorCode = error.code;
              var errorMessage = error.message;
            });
        } else {
            dispatch(loginError('something went wrong, try again later on :('));
        }
    }
}

export const getSuggestions = (token, showToast, toastManager) => {
    return (dispatch) => {
        dispatch({ type: SUGGESTIONS_LOADING, payload: true });
        axios.post(URL + '/suggestions', {}, {headers: { authorization: `Bearer ${token}` }}).then((res) => {
            if (res.data.success) {
                dispatch({ type: SUGGESTIONS_SUCCESS, payload: res.data, showToast: showToast, toastManager: toastManager });
            } else {
                dispatch({ type: SUGGESTIONS_FAILURE, payload: false });
            }
        }).catch((e) => {
            console.log(e);
            toast.warning("failed to load suggestions :(");
            dispatch({ type: SUGGESTIONS_FAILURE, payload: false });
        });
    }
}

export const getInformation = (token) => {
    return (dispatch) => {
        dispatch({ type: INFORMATION_LOADING, payload: true });
    
        axios.post(URL + '/user', {}, {headers: { authorization: `Bearer ${token}` }}).then((res) => {
          // if the user isn't found we need to set a locked flag
            if (res.data.not_found) {
                // user either hasn't registered yet or has been deleted
                dispatch({ type: USER_NOT_REGISTERED });
            } else if (res.data.success) {
                dispatch({ type: INFORMATION_SUCCESS, payload: res.data });
            } else {
                dispatch({ type: INFORMATION_FAILURE, payload: false });
            }
        }).catch((e) => {
          console.log(e);
          toast.warning("failed to load user data :(");
          dispatch({ type: INFORMATION_FAILURE, payload: false });
        });
      }
}

// login or signup to loving pine and send this person a compliment to receive 3 free reveal tokens which can be 
// used to reveal the identity of the person who sent you compliment!

export const getPoll = (new_poll, skipped, mode, token) => {
    return (dispatch) => {
        dispatch({ type: POLL_LOADING, payload: true });

        axios.post(URL + '/poll', {new_poll, skipped, mode}, {headers: { authorization: `Bearer ${token}` }}).then((res) => {
            if (!res.data.success) {
                dispatch({ type: POLL_FAILURE, payload: false });
                return;
            }
            
            if (res.data.limited) {
                dispatch({ type: POLL_TIMEOUT, payload: res.data.time_to_wait });
            } else {
                dispatch({ type: POLL_SUCCESS, payload: res.data });
            }
        }).catch((e) => {
            toast.warning("failed to load poll data :(");
            dispatch({ type: POLL_FAILURE, payload: false });
        });
    }
}

export const shufflePoll = (qid, token) => {
    return (dispatch) => {
        // post to /shuffle the uid
        dispatch({ type: POLL_LOADING, payload: true });
        dispatch({ type: SHUFFLING_START, payload: true });

        axios.post(URL + '/shuffle', { qid }, {headers: { authorization: `Bearer ${token}` }}).then((res) => {
            if (res.data.success) {
                dispatch({ type: POLL_SHUFFLE, payload: res.data });
            }

            dispatch({ type: SHUFFLING_END, payload: true });
        }).catch((e) => {
            console.log(e);
            toast.warning("failed to shuffle poll :(");
            dispatch({ type: POLL_FAILURE, payload: false });
            dispatch({ type: SHUFFLING_END, payload: true });
        });
    }
}

export const answerPoll = (token, answer, skipped, toastManager, rewardManager) => {
    return (dispatch) => {
        axios.post(URL + '/answer', {token, answer, skipped}, {headers: { authorization: `Bearer ${token}` }}).then((res) => {
            if (res.data.award) {
                dispatch({ type: POLL_AWARD, payload: res.data.reveals_reward });
            }

            if (res.data.user_chose_target) {
                toastManager.add("🎉 congratulations, you've won 3 reveals!", { appearance: 'success', autoDismiss: true });
                rewardManager.rewardMe();
                dispatch({ type: POLL_TARGET_CHOSE });
            }

        }).catch((e) => {
            console.log(e);
            toast.warning("failed to answer poll :(");
            dispatch({ type: POLL_FAILURE, payload: false });
        });
    }
}

export const sendPoll = (token, question, choices, recipient) => {
    return (dispatch) => {
        // post to /custom endpoint
        dispatch({ type: POLL_LOADING, payload: true });
        axios.post(URL + '/custom', {token, question, choices, recipient}, {headers: { authorization: `Bearer ${token}` }})
        .then((res) => {
            // console.log(res.data);
            if (!res.data.success) {
                dispatch({ type: POLL_FAILURE, payload: false });
                return;
            }

            dispatch({ type: POLL_SENT });
            
        }).catch((e) => {
            console.log(e);
            toast.warning("failed to send poll :(");
            dispatch({ type: POLL_FAILURE, payload: false });
        });
    }
}

export const setCustomPollChoice = (index, choice) => {
    return (dispatch) => {
        dispatch({ type: CUSTOM_CHOICES, payload: {index, choice} });
    }
}

export const setCustomPollRecipient = (recipient) => {
    return (dispatch) => {
        dispatch({ type: CUSTOM_RECIPIENT, payload: recipient });
    }
}


// this needs to be protected with auth uid
export const sendAffirmation = (recipientName, recipientEmail, to, message, qid, token, toastManager) => {
    return (dispatch) => {
        dispatch({ type: AFFIRMATION_SENDING, payload: true });
        axios.post(URL + '/affirmation', {to, message, qid, recipientName, recipientEmail}, {headers: { authorization: `Bearer ${token}` }}).then((res) => {
            dispatch({ type: AFFIRMATION_SUCCESS, payload: true });
            toastManager.add('affirmation sent!', { appearance: 'success', autoDismiss: true });
        }).catch((e) => {
          console.log(e);
          toast.warning("failed to send affirmation.");
          dispatch({ type: AFFIRMATION_FAILURE, payload: true });
        });
    }
}

export const unlockAffirmation = (uid, affirmation_id, toastManager, rewardManager) => {
    return (dispatch) => {
        dispatch({ type: UNLOCK_LOADING, payload: true });

        axios.post(URL + '/affirmation/unlock', {uid, affirmation_id}).then((res) => {
            dispatch({ type: UNLOCK_SUCCESS, payload: res.data });
            // toastManager.add('Affirmation unlocked!', { appearance: 'success', autoDismiss: true });
            rewardManager.rewardMe();
        }).catch((e) => {
          console.log(e);
          toast.warning("not enough reveals! send an affirmation or answer some polls to get more!");
          dispatch({ type: UNLOCK_FAILURE, payload: true });
          rewardManager.punishMe();
        });
      }
}

export const revealName = (uid, pid, sid, token, rewardManager) => {
    return (dispatch) => {
        dispatch({ type: REVEAL_LOADING, payload: pid });

        axios.post(URL + '/reveal', {uid, pid, sid}, {headers: { authorization: `Bearer ${token}` }}).then((res) => {
            if (res.data.success) {
                dispatch({ type: REVEAL_SUCCESS, payload: res.data });
                rewardManager.rewardMe();
            } else {
                toast.warning(res.data.message);
                dispatch({ type: REVEAL_FAILURE, payload: false });
                rewardManager.punishMe();
            }
        }).catch((e) => {
            console.log(e);
            toast.warning("failed to reveal, try again later :(");
            dispatch({ type: REVEAL_FAILURE, payload: false });
            rewardManager.punishMe();
        });
    }
}

export const inviteUser = (uid, token, toastManager) => {
    return (dispatch) => {
        dispatch({ type: INVITE_LOADING });

        axios.post(URL + '/invite', {invitee_uid: uid}, {headers: { authorization: `Bearer ${token}` }}).then((res) => {
            if (res.data.success) {
                dispatch({ type: INVITE_SUCCESS });
                toastManager.add('✉️ invitation has been successfully sent!', { appearance: 'success', autoDismiss: true });
                // get a poll
                dispatch(getPoll(false, false, 'friendly', token))
            }
        }).catch((e) => {
            console.log(e);
            toast.warning("failed to send invitation :(");
            dispatch({ type: INVITE_FAILURE, payload: false });
        });
    }
}

export const logInvite = (id, uid) => {
    return (dispatch) => {
        axios.post(URL + '/invited', { invite_code: id, uid }).then((res) => {
            window.location.href = '/landing';
        }).catch((e) => {
            window.location.href = '/landing';
        });
    }
}