import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import {ThemeProvider} from "styled-components";

import FirebaseConfig from './FirebaseConfig';
import thunk from 'redux-thunk';

import firebase from 'firebase/compat/app';
import { reduxFirestore, getFirestore, createFirestoreInstance } from 'redux-firestore'

import { getFirebase, isLoaded } from 'react-redux-firebase'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import 'react-toastify/dist/ReactToastify.css';

import { createStore, applyMiddleware, compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import reducers from './reducers';

import { GlobalStyles } from "./components/globalStyles";
import { lightTheme, darkTheme } from "./Theme";

import { composeWithDevTools } from 'redux-devtools-extension';
import { getFeed, getInformation, getSuggestions, getPoll, verifyUser, logInvite } from './actions/user';

if (module.hot) {
  module.hot.accept();
}

const store = createStore(reducers, composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})),
    reduxFirestore(FirebaseConfig),
  )
);

function LoadingScreen({ children }) {
  const auth = useSelector(state => state.firebase.auth);
  // const user = useSelector(state => state.user);

  // console.log('autihng: ', user)
  // const isDarkMode = localStorage.getItem('theme') === 'dark' ? true : false;

  if (!isLoaded(auth)) {
    return (
      <ThemeProvider theme={darkTheme}>
        <GlobalStyles/>
        <div className="d-flex justify-content-center align-items-center" style={{height: '90vh', flexDirection: 'column', opacity: 0.3}}>
          <div className="loader"></div><br />
          <div className="sr-only" id="loading-text" style={{ marginBottom: 15, fontWeight: 'bold' }}>don't look, I'm getting ready! 🙈</div>
        </div>
      </ThemeProvider>
    )
  } 

  return children
}

// add error state for login
// test registration again (should work)
// test login again (should work)
// fix when they first do polls!
// write email for assassin
// write email for target

// this is getting called multiple times
function AuthIsLoaded({ children }) {
    const auth = useSelector(state => state.firebase.auth);
    const dispatch = useDispatch();
    
    // auth not loading in fast enough
    // login page not moving over to app page
    // maybe use dispatch here
    
    if (auth.isLoaded && auth.isEmpty) {
      // redirect to login page (if we aren't already there)
      // window.location.href = '/login';

      if (window.location.pathname !== '/landing') {
        if (window.location.pathname !== '/signup') {
          window.location.href = '/signup';
        }
      }
    }

    // if the user chooses their target, confetti should fly everywhere

    useEffect(() => {
      // console.log('auth is loaded: ', window.location.pathname)
      if (window.location.pathname.includes('invited')) {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');
        const uid = searchParams.get('uid');

        if (code && uid) {
          dispatch(logInvite(code, uid));
        }
      }

      // todo: test raw registration (with neo4j node remove)
      // todo: test invite code
      // todo: finish up the email
      // todo: gather 500 users (test with 100 first)
      // todo: verify/register should just redirect user

      if (auth.isLoaded && !auth.isEmpty && auth.uid) {
        // need to hit this less, its a lot of calls
        // after registering once, this doesn't get called

        firebase.auth().currentUser.getIdToken().then((token) => {
          // check if the current url is the signup page
          if (window.location.pathname.includes('unlock')) {
            const searchParams = new URLSearchParams(window.location.search);
            const code = searchParams.get('code');

            if (code && code.length > 0) {
              dispatch(verifyUser(token, code));
            }
          }

          dispatch(getInformation(token));
          dispatch(getSuggestions(token, false, null));
          dispatch(getPoll(false, false, 'friendly', token));
        })

        // limit the number of times this is called
      }
    }, [auth, dispatch]);

    return children;
}

const firebaseReduxProps = {
    firebase,
    config: FirebaseConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
};

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...firebaseReduxProps}>
        <LoadingScreen>
          <AuthIsLoaded>
            <ToastProvider autoDismiss placement="top-center">
              <App  />
            </ToastProvider>
          </AuthIsLoaded>
        </LoadingScreen>
      </ReactReduxFirebaseProvider>
    </Provider>,
);